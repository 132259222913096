import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomRouterHandlerComponent } from './platform/custom-router-handler/custom-router-handler.component';

import { ProcessOrchestrationLandingComponent } from './process-orchestration-landing/process-orchestration-landing.component';
import { QuickAccessOrchestrationLandingComponent } from './quick-access-orchestration-landing/quick-access-orchestration-landing.component';

import { InternalFlowListComponent } from './internal-flow-list/internal-flow-list.component';
import { ProcessListComponent } from './process-list/process-list.component';
import { EventApiListComponent } from './event-api-list/event-api-list.component';
import { EventSchemaListComponent } from './event-schema-list/event-schema-list.component';
import { ObjectModelerListComponent } from './object-modeler-list/object-modeler-list.component';
import { ApiConnectorListComponent } from './api-connector-list/api-connector-list.component';
import { SystemPropertiesListComponent } from './system-properties-list/system-properties-list.component';

import { ProcessStudioCanvasComponent } from './process-studio-canvas/process-studio-canvas.component';
import { EventApiStudioComponent } from './event-api-studio/event-api-studio.component';
import { EventSchemaStudioComponent } from './event-schema-studio/event-schema-studio.component';
import { ApiConnectorConfigurationComponent } from './api-connector-configuration/api-connector-configuration.component';
import { ObjectModelerEditorComponent } from './object-modeler-editor/object-modeler-editor.component';
import { LoginComponent } from './login/login.component';
import { RouterGaurdService as RouterGaurd } from './service/auth0/router-gaurd.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { InternalFlowStudioComponent } from './internal-flow-studio/internal-flow-studio.component';
import { AnalyticsListComponent } from './analytics-list/analytics-list.component';
import { AnalyticsStudioComponent } from './analytics-studio/analytics-studio.component';
import { CcpAnalyticsLandingComponent} from './reports/ccp-analytics-landing/ccp-analytics-landing.component';
import { MonitorLogsComponent } from './monitor-logs/monitor-logs.component';
import { ProcessLogComponent } from './monitor-logs/process-log/process-log.component';
import { UserListComponent } from './user-management/users/user-list/user-list.component';
import { UsersCreateComponent } from './user-management/users/users-create/users-create.component';

const routes: Routes = [
  { path: 'index.html', component: CustomRouterHandlerComponent },
  {
    path: 'landing', component: ProcessOrchestrationLandingComponent, canActivate: [RouterGaurd],
    children: [
      { path: '', outlet: 'studio', component: QuickAccessOrchestrationLandingComponent, canActivate: [RouterGaurd] },

      { path: 'internal-flow-list', outlet: 'studio', component: InternalFlowListComponent, canActivate: [RouterGaurd] },
      { path: 'internal-flow-studio/new', outlet: 'studio', component: InternalFlowStudioComponent, canActivate: [RouterGaurd] },
      { path: 'internal-flow-studio/:id', outlet: 'studio', component: InternalFlowStudioComponent, canActivate: [RouterGaurd] },

      { path: 'process-list', outlet: 'studio', component: ProcessListComponent, canActivate: [RouterGaurd] },
      { path: 'process-studio/new', outlet: 'studio', component: ProcessStudioCanvasComponent, canActivate: [RouterGaurd] },
      { path: 'process-studio/:name', outlet: 'studio', component: ProcessStudioCanvasComponent, canActivate: [RouterGaurd] },

      { path: 'event-api-list', outlet: 'studio', component: EventApiListComponent, canActivate: [RouterGaurd] },
      { path: 'event-api-studio/new', outlet: 'studio', component: EventApiStudioComponent, canActivate: [RouterGaurd] },
      { path: 'event-api-studio/:inputEvent', outlet: 'studio', component: EventApiStudioComponent, canActivate: [RouterGaurd] },

      { path: 'event-schema-list', outlet: 'studio', component: EventSchemaListComponent, canActivate: [RouterGaurd] },
      { path: 'event-schema-studio/new', outlet: 'studio', component: EventSchemaStudioComponent, canActivate: [RouterGaurd] },
      { path: 'event-schema-studio/:name', outlet: 'studio', component: EventSchemaStudioComponent, canActivate: [RouterGaurd] },

      { path: 'object-list', outlet: 'studio', component: ObjectModelerListComponent, canActivate: [RouterGaurd] },
      { path: 'object-modeler-editor/new', outlet: 'studio', component: ObjectModelerEditorComponent, canActivate: [RouterGaurd] },
      { path: 'object-modeler-editor/:id', outlet: 'studio', component: ObjectModelerEditorComponent, canActivate: [RouterGaurd] },

      { path: 'intergrations-list', outlet: 'studio', component: ApiConnectorListComponent, canActivate: [RouterGaurd] },
      { path: 'intergrations-configuration/:id', outlet: 'studio', component: ApiConnectorConfigurationComponent, canActivate: [RouterGaurd] },

      { path: 'custom-variables-list', outlet: 'studio', component: SystemPropertiesListComponent, canActivate: [RouterGaurd] },
      
      { path: 'user-list', outlet: 'studio', component: UserListComponent, canActivate: [RouterGaurd] },
      { path: 'user-editor/new', outlet: 'studio', component: UsersCreateComponent, canActivate: [RouterGaurd] },
      { path: 'user-editor/:id', outlet: 'studio', component: UsersCreateComponent, canActivate: [RouterGaurd] },

      { path: 'analytics-list', outlet: 'studio', component: AnalyticsListComponent, canActivate: [RouterGaurd] },
      { path: 'analytics-studio/new', outlet: 'studio', component: AnalyticsStudioComponent, canActivate: [RouterGaurd] },
      { path: 'analytics-studio/:id', outlet: 'studio', component: AnalyticsStudioComponent, canActivate: [RouterGaurd] },
    
      { path: 'monitor-logs', outlet: 'studio', component: MonitorLogsComponent, canActivate: [RouterGaurd] },
      { path: 'monitor-logs/process/:processId', outlet: 'studio', component: ProcessLogComponent, canActivate: [RouterGaurd] },

      { path: 'monitorLogs', outlet: 'studio', component: MonitorLogsComponent, canActivate: [RouterGaurd] },
      { path: 'monitorLogs/process/:processId', outlet: 'studio', component: ProcessLogComponent, canActivate: [RouterGaurd] }
    ]
  },
  { path: "analytics/:id", component: CcpAnalyticsLandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'unautorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/landing', pathMatch: 'full' },
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }