import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsListActions } from '../data-grid/analytics-list-actions.component';
import { AnalyticsTypeComponent } from '../data-grid/analytics-type.component';
import { AnalyticsCreateWizardComponent } from './analytics-create-wizard/analytics-create-wizard.component';
import { DateTimeRendererComponent } from '../data-grid/date-time-renderer.component';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { ApiUtilService } from '../service/api-util.service';
import { CommonService } from '../service/common.service';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';
declare var $: any;

@Component({
  selector: 'ccp-analytics-list',
  templateUrl: './analytics-list.component.html',
  styleUrls: ['./analytics-list.component.css']
})
export class AnalyticsListComponent implements OnInit {

  @ViewChild(AnalyticsCreateWizardComponent, {static: false}) analyticsCreateWizardComponent: AnalyticsCreateWizardComponent;
  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;
  public selectedRowObject: any= {};
  selectedReport: any = undefined;
  rowSelection:string = "single";
  reportsData: any = {};
  reportMetadataList: any[] = [];

  chartIconDetails: any = {
    "column": { icon: "floatBar", label: "Column" },
    "bar": { icon: "horizontal", label: "Bar" },
    "pie": { icon: "pie", label: "Pie" },
    "donut": { icon: "donut", label: "Donut" },
    "line": { icon: "line", label: "Line" },
    "bubble": { icon: "bubble", label: "Bubble" },
    "gauge": { icon: "gauge", label: "Gauge" },
    "solidgauge": { icon: "gauge", label: "Solid Gauge" },
    "funnel": { icon: "funnel", label: "Funnel" },
    "area": { icon: "area", label: "Area" },
    "scatter": { icon: "scatter", label: "Scatter" },
    "columnpyramid": { icon: "pyramid", label: "Pyramid" },
    "lollipop": { icon: "lollipop", label: "Lollipop" },
    "radialbar": { icon: "radialBar", label: "Radial Bar" },
    "streamgraph": { icon: "streamGraph", label: "Streamgraph" },
    "dumbbell": { icon: "dumbbell", label: "Dumbbell" },
    "candlestick": { icon: "candlestick", label: "Candlestick" },
    "heatmap": { icon: "heatmap", label: "HeatMap" },
    "table": { icon: "table", label: "Table" },
    "reports": { icon: "table", label: "Report" }
  };

  constructor(private apiUtilService: ApiUtilService,
              private toastr: ToastrService,
              private router: Router,
              private cs: CommonService) { }

  ngOnInit() {
    document.title = "CCS - Analytics";
    this.initDataGridDetails();
    $('#sidebar').removeClass("active");
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 300)
    this.getAnalyticsData();
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name',sort: 'asc', sortable: true, suppressMovable: true, width: 280, cellClass: 'text-custom-link'  },
      { headerName: 'Description', field: 'description', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color'  },
      { headerName: 'Chart Type', field: 'analyticsType', sortable: true, suppressMovable: true, width: 150, cellClass: 'ag-text-val-color'  },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 130, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 150, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 120, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      analyticsListActions: AnalyticsListActions,
      analyticsTypeComponent: AnalyticsTypeComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  setSelected(selectedReportRow: any) : void{
    if (!selectedReportRow.data) {
      this.selectedReport = undefined;
      return;
    }
    this.selectedReport = selectedReportRow.data;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'analyticsListActions';
            break;
          case 'Chart Type':
            colDef.cellRenderer = 'analyticsTypeComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  skipToStudio(){
    this.router.navigate(['landing', { outlets: { studio: 'analytics-studio/new' } }]);
  }

  async invokeGridAction(params: any) {
    let currentRowData = params.rowData;
    let type: string = params.type;
    if(type === 'edit') {
      this.router.navigate(['landing', { outlets: { studio: 'analytics-studio/'+ currentRowData.id } }]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } else if(type === 'clone') {
      this.selectedRowObject = currentRowData;
      $('#clone-report-modal').modal('show');
    }
  }

  getAnalyticsData() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_REPORT_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.reportsData = res.body || {};
        this.reportMetadataList = (Object.keys(this.reportsData) || []).map( val => this.reportsData[val]);
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
      }
    );
  }

  deleteEventAPI() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_REPORT_METADATA_BY_ID;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.selectedRowObject.id), currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        $('#delete-confirmation-modal').modal('hide');
        this.getAnalyticsData();
        this.toastr.success("Deleted Successfully!");
       },
       (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Delete Failed');
        console.error(err);
      }
    );
  }

  cloneReport(isEdit: any) {
    $('#clone-report-modal').modal('hide');
    let rowObj = this.selectedRowObject;
    let reqBody = {name: rowObj.name+' - clone', description: rowObj.description}
    let currentAPIProperty = apiProperties.CLONE_REPORT_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", rowObj.id), currentAPIProperty.method, reqBody).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        if(isEdit) {
          this.router.navigate(['landing', { outlets: { studio: 'analytics-studio/'+ res.body.id } }]);
        } else {
          this.getAnalyticsData();
        }
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
      }
    );
  }

  editReportGrid(): any{
    this.invokeGridAction({rowData:this.selectedReport , type:'edit'});
  }

  cloneReportGrid(): any{
    this.invokeGridAction({rowData:this.selectedReport , type:'clone'});
  }

  deleteReportGrid(): any{
    this.invokeGridAction({rowData:this.selectedReport , type:'delete'});
  }

}
