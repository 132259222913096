import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ProcessStudioService } from '../service/process-studio.service';
import { EventApiService } from '../service/event-api.service';
import { InternalFlowService } from '../service/internal-flow.service';
import { apiProperties } from '../utility/constants';
import { Utils } from './../utility/util';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ScriptEditorService } from '../service/script-editor.service';
declare var $: any;

@Component({
  selector: 'ccp-event-api-settings',
  templateUrl: './event-api-settings.component.html',
  styleUrls: ['./event-api-settings.component.css']
})
export class EventApiSettingsComponent implements OnInit {
  @ViewChild('textarea_scriptEditor', { static: true }) editor: any;
  @ViewChild('textarea_finallyScriptEditor', { static: true }) finallyScriptEditor: any;
  @ViewChild('textarea_preScriptEditor', { static: true }) preScriptEditor: any;
  @ViewChild('textarea_postScriptEditor', { static: true }) postScriptEditor: any;
  @ViewChild('textarea_cUrlCmdEditor', { static: false }) cUrlCmdEditor: any;

  public apiSettings: any;
  public decision: any;
  public scriptEditor: any;
  public contextMenuActions: any;
  public contextMenuVisibility: any;
  public _apiProperties: any;
 
  public devMode: string;

  public showSendButton: boolean = false;
  public showDebugButton: boolean = false;
  public isInternalFlow: boolean;
  public prePostScriptStepList: any[] = ["Database", "REST Service Call", "Internal Application Call", "FTP", "WQM", "GCS"];

  public activeInputTab: string = 'RAW';
 
  constructor(
    private processStudioService: ProcessStudioService,
    public eventApiService: EventApiService,
    public internalFlowService: InternalFlowService,
    public scriptEditorService: ScriptEditorService,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private router: Router
  ) {

  }

  ngOnInit() { 
    this.apiSettings = this.eventApiService.apiSettings;
    this.decision = this.eventApiService.decision;
    this.scriptEditor = this.scriptEditorService.scriptEditor;
    this.contextMenuActions = this.eventApiService.contextMenuActions;
    this.contextMenuVisibility = this.contextMenuActions.visibility;
    this._apiProperties = apiProperties;
    this.devMode = localStorage.getItem("DEV-MODE") || 'cloud';
    const isCloudDebugEnabled = localStorage.getItem("IS_CLOUD_DEBUG_ENABLED") == "true";
    this.showSendButton = this.devMode == 'cloud';
    this.showDebugButton = this.devMode == 'local' || isCloudDebugEnabled;
    if(window.location.pathname.indexOf("internal-flow-studio") !== -1){
      this.isInternalFlow = true;
    } else {
      this.isInternalFlow = false;
    }
  }

  // ngAfterViewInit() {
  //   if(this.apiSettings.isCallFrmProcessFlow){
  //     this.processStudioService.initAPI();
  //   }
  // }

  showScriptEditor(scriptKey: string, scriptType: string) {
    let editor: any;
    let selectedStep: any = this.apiSettings.selectedAPIStep;
    switch (scriptType) {
      case 'JavaScript':
        editor = this.editor;
        $("#scriptEditorModal").modal("show");
        break;
      case 'FinallyScript':
        editor = this.finallyScriptEditor;
        $("#finallyScriptEditorModal").modal("show");
        break;
      case 'PreScript':
        editor = this.preScriptEditor;
        $("#preScriptEditorModal").modal("show");
        break;
      case 'PostScript':
        editor = this.postScriptEditor;
        $("#postScriptEditorModal").modal("show");
        break;
      default:
        break;
    }
    this.scriptEditorService.initReadOnlyLines(editor);
    this.scriptEditorService.setScriptValue(scriptType, selectedStep[scriptKey] || '', selectedStep.isAsyncExecution, editor);
  }

  saveScript(scriptType: string) {
    switch (scriptType) {
      case 'JavaScript':
        this.apiSettings.selectedAPIStep.script = this.scriptEditorService.getScriptValue(null, this.editor);
        $("#scriptEditorModal").modal("hide");
        break;
      case 'FinallyScript':
        this.apiSettings.selectedAPI.metadata.finallyBlockDtls.script = this.scriptEditorService.getScriptValue(null, this.finallyScriptEditor);
        $("#finallyScriptEditorModal").modal("hide");
        break;
      case 'PreScript':
        this.apiSettings.selectedAPIStep.preScript = this.scriptEditorService.getScriptValue(null, this.preScriptEditor);
        $("#preScriptEditorModal").modal("hide");
        break;
      case 'PostScript':
        this.apiSettings.selectedAPIStep.postScript = this.scriptEditorService.getScriptValue(null, this.postScriptEditor);
        $("#postScriptEditorModal").modal("hide");
        break;
      default:
        break;
    }
  }

  deleteEventAPI() {
    Utils.loader('#page-loader', 'show');
    if(this.isInternalFlow){
      this.internalFlowService.deleteInternalFlow(this.apiSettings.selectedAPI.id, (res: any) => {
        this.toastr.success('Internal Flow (' + this.apiSettings.selectedAPI.name + ') Deleted Successfully');
        $('#delete-confirmation-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        this.router.navigateByUrl('/landing?nav=internalFlow'); 
      }, (err: any) => {
        console.log(err);
        this.toastr.error((err.error || {}).message || 'Internal Flow (' + this.apiSettings.selectedAPI.name + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
      });
    } else {
      this.eventApiService.deleteEventAPI(this.apiSettings.selectedAPI.id, (res: any) => {
        this.toastr.success('Event API (' + this.apiSettings.selectedAPI.name + ') Deleted Successfully');
        $('#delete-confirmation-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        this.router.navigateByUrl('/landing?nav=eventAPI');
      }, (err: any) => {
        console.log(err);
        this.toastr.error('Event API (' + this.apiSettings.selectedAPI.name + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
      });
    }
  }

  showAPISimulatorModal(isApiQuickTest: boolean) {
    var requestObject: any = {};
    if (!this.isInternalFlow) {
      requestObject = { name: this.apiSettings.selectedAPI.inputEvent || "", userInput: {}, processContext: {} };
      if (this.apiSettings.isCallFrmProcessFlow) requestObject['processName'] = this.processStudioService.processMetadata.name;
      else requestObject['processName'] = ((this.eventApiService.eventSchemaList || []).find((schema) => schema.name == requestObject.name) || {}).processName;
    }
    this.activeInputTab = 'RAW';
    this.apiSettings.showAPISimulatorModal(isApiQuickTest, requestObject);
  }

  removeScriptRefs(scriptKey: string) {
    delete this.apiSettings.selectedAPIStep[scriptKey];
    delete this.apiSettings.selectedAPIStep[scriptKey + 'Id'];
  }

  generateCurl() {
    this.ref.detectChanges();
    Utils.loader('#page-loader', 'show');
    this.apiSettings.generateCurl(() => {
      let editor = this.cUrlCmdEditor;
      this.scriptEditorService.initReadOnlyLines(editor);
      this.scriptEditorService.setScriptValue('cUrl', this.apiSettings.curl, false, editor);
      Utils.loader('#page-loader', 'hide');
    });
  }

  copySnippetCallback(params: any) {
    var iconTag = $(params.event);
    if($(params.event).is("button")) iconTag = $(params.event).find('i');
    iconTag.removeClass("fa-clone");
    iconTag.addClass("fa-check-circle");
    this.toastr.info('Copied to clipboard', '', { positionClass: 'toast-bottom-right' });
    setTimeout(() => {
      iconTag.removeClass("fa-check-circle");
      iconTag.addClass("fa-clone");
    }, 2000);
  }

}