import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ScriptEditorService {

    scriptEditor: any = {
        script: {
          JavaScript: {
            mode: "javascript",
            script_prefix: "function execute(request, response, scope, HTTP_STATUS_CODE){\n",
            async_script_prefix: "function execute(request, response, scope, HTTP_STATUS_CODE, callback){\n",
            script_body: "  var body = request.body;" +
                   "\n  var query = request.query;" +
                   "\n  var params = request.params;" +
                   "\n  var headers = scope.headers;" +
                   "\n  var output = scope;" +
                   "\n  " +
                   "\n  /*" +
                   "\n    Write Business Logic Here..." +
                   "\n    For Async Execution return response as \"callback(error, output);\" if there is no error then send as null in first param" +
                   "\n    To send a custom status code, use syntax \"response.send(HTTP_STATUS_CODE.SUCCESS, \"<reponse message>\");\"" +
                   "\n  */" +
                   "\n  " +
                   "\n  return output;",
            script_suffix: "\n}",	
          },
          FinallyScript: {
            mode: "javascript",
            script_prefix: "function execute(apiResponseDetails, response, scope, HTTP_STATUS_CODE) {\n",
            script_body: "  var isApiExecutionSuccess = apiResponseDetails.isSuccess;" +
                   "\n  var stepErrorList = apiResponseDetails.stepErrorList;" +
                   "\n  var apiResponse = apiResponseDetails.response;" +
                   "\n  " +
                   "\n  /*" +
                   "\n    To send a custom status code, use syntax \"response.send(HTTP_STATUS_CODE.SUCCESS, \"<reponse message>\");\"" +
                   "\n  */" +
                   "\n  " +
                   "\n  return apiResponseDetails;",
            script_suffix: "\n}",	
          },
          ConnectorScript_pre_rest_request: {
            mode: "javascript",
            script_prefix: "function execute(requestInfo){\n",
            script_body: "  var output = requestInfo;" +
                   "\n  " +
                   "\n  /*" +
                   "\n    Write Business Logic Here..." +
                   "\n  */" +
                   "\n  " +
                   "\n  return output;",
            script_suffix: "\n}",	
          },
          ConnectorScript_post_rest_request: {
            mode: "javascript",
            script_prefix: "function execute(requestDetails, responseDetails, scope){\n",
            script_body: "  var output = responseDetails;" +
                   "\n  " +
                   "\n  /*" +
                   "\n    Write Business Logic Here..." +
                   "\n  */" +
                   "\n  " +
                   "\n  return output;",
            script_suffix: "\n}",	
          },
          PreScript: {
            mode: "javascript",
            script_prefix: "function execute(request, response, scope, HTTP_STATUS_CODE){\n", 
            script_body: "  var body = request.body;" +
                   "\n  var query = request.query;" +
                   "\n  var params = request.params;" +
                   "\n  var headers = scope.headers;" +
                   "\n  var output = scope;" +
                   "\n  " +
                   "\n  /*" +
                   "\n    Write Business Logic Here..." +
                   "\n  */" +
                   "\n  " +
                   "\n  return output;",
            script_suffix: "\n}",	
          },
          PostScript: {
            mode: "javascript",
            script_prefix: "function execute(request, response, scope, HTTP_STATUS_CODE){\n",
            script_body: "  var body = request.body;" +
                   "\n  var query = request.query;" +
                   "\n  var params = request.params;" +
                   "\n  var headers = scope.headers;" +
                   "\n  var output = scope;" +
                   "\n  " +
                   "\n  /*" +
                   "\n    Write Business Logic Here..." +
                   "\n  */" +
                   "\n  " +
                   "\n  return output;",
            script_suffix: "\n}",	
          }
        },
        options: {
          _defaults: {
            "javascript": {
              theme: 'blackboard',
              mode: 'javascript',
              lineNumbers: true,
              lineWrapping: true,
              foldGutter: true,
              scrollbarStyle: 'overlay',
              gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
              autoCloseBrackets: true,
              matchBrackets: true,
              lint: true,
              viewEditorDelay: 300
            },
            "shell": {
              theme: 'blackboard',
              mode: 'shell',
              scrollbarStyle: 'overlay',
              gutters: ['CodeMirror-lint-markers'],
              lint: true,
              viewEditorDelay: 300
            }
          },
          _get: function(mode: string, type: string) {
            const currentEditorDetails = 
              { 
                "businessLogic": { "readOnlyLine": [1, 2, 3, 4, 5, 6, -2, -1], "excludeLine_getValue": [1, -1] }, 
                "finallyScriptBlock": { "readOnlyLine": [1, 2, 3, 4, -2, -1], "excludeLine_getValue": [1, -1] }, 
                "connectorScript": { "readOnlyLine": [1, 2, -2, -1], "excludeLine_getValue": [1, -1] },
                "cUrlCmd": { "isReadOnlyEditor": true }
              };
            return Object.assign(this._defaults[mode], currentEditorDetails[type]);
          },
        }
      }

    constructor() {}

    initReadOnlyLines(editor: any) {
        var currentEditor = editor;
        var opt: any = currentEditor.codeMirror.options;
        if (!opt.isReadOnlyEditor) {
          var _readOnlyLineInfo = {};
          (opt.readOnlyLine || []).forEach((lineNum: number) => { _readOnlyLineInfo[lineNum] = "ALL"; });
          Object.keys(opt.readOnly_partialLine || {}).forEach((lineNum) => { _readOnlyLineInfo[lineNum] = opt.readOnly_partialLine[lineNum]; });
          var _isReadOnlyLine = function(from: any, to: any, lineCount: number){
            var startLine = from.line;
            var endLine = to.line;
            var startCh = from.ch;
            var endCh = to.ch;
            var startLineInfo_1 = _readOnlyLineInfo[startLine + 1];
            var startLineInfo_2 = _readOnlyLineInfo[startLine - lineCount];
            var endLineInfo_1 = _readOnlyLineInfo[endLine + 1];
            var endLineInfo_2 = _readOnlyLineInfo[endLine - lineCount];
            if(startLineInfo_1 == "ALL" || startLineInfo_1 > startCh || startLineInfo_2 == "ALL" || startLineInfo_2 > startCh ||
                endLineInfo_1 == "ALL" || endLineInfo_1 > endCh || endLineInfo_2 == "ALL" || endLineInfo_2 > endCh){
              return true;
            }
            return false;
          };
          // listen for the beforeChange event, test the changed line number, and cancel
          currentEditor.codeMirror.on('beforeChange',function(cm: any, change: any) {
            if(change.origin != "setValue"){
              if(_isReadOnlyLine(change.from, change.to, cm.lineCount())) change.cancel();
            }
          });
        } else {
          // listen for the beforeChange event, test the changed line number, and cancel
          currentEditor.codeMirror.on('beforeChange',function(cm: any, change: any) {
            if(change.origin != "setValue") change.cancel();
          });
        }
      }
    
      setScriptValue(scriptType: string, value: string, isAsyncExecution: boolean, editor: any) {
        var currentEditor: any;
        currentEditor = editor;
        var scriptInfo = this.scriptEditor.script[scriptType];
        if(scriptInfo){
          var script_prefix = scriptInfo.script_prefix;
          if(isAsyncExecution && scriptInfo.async_script_prefix){
            script_prefix = scriptInfo.async_script_prefix;
          }
          value = script_prefix + ((value || "") != "" ? value : scriptInfo.script_body) + scriptInfo.script_suffix;
        }
        setTimeout(() => currentEditor.codeMirror.refresh(), 200);
        currentEditor.codeMirror.setValue(value);
      }

      getScriptValue(excludeLineList?: number[], editor?:any) {
        var currentEditor = editor;
        excludeLineList = excludeLineList || (currentEditor.codeMirror.options || {}).excludeLine_getValue || [];
        var valueList = currentEditor.codeMirror.getValue(false);
        if(excludeLineList.length > 0){
          var totalLine = valueList.length;
          var updatedValueList = [];
          valueList.forEach((value: any, index: number) => {
            if(excludeLineList.indexOf(index + 1) == -1 && excludeLineList.indexOf(index - totalLine) == -1){
              updatedValueList.push(value);
            }
          });
          return updatedValueList.join("\n");
        }else{
          return valueList.join("\n");
        }
      }
}