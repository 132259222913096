import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { apiProperties } from '../utility/constants';
import { Utils } from './../utility/util';
import { Router } from '@angular/router';
declare var $: any;

import { ApiUtilService } from './api-util.service';

@Injectable({
    providedIn: 'root'
  })

  export class InternalFlowService {

    public internalFlowList: any[] = [];

    constructor(
        private apiUtilService: ApiUtilService,
        private toastr: ToastrService,
        private router: Router
      ) { }

      getAllInternalFlows(successCallback: any, errorCallback: any) {
        let currentAPIProperty = apiProperties.GET_ALL_INTERNAL_FLOWS;
        this.internalFlowList = [];
        Utils.loader('#page-loader', 'show');
        this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.internalFlowList = res.body;
            successCallback(res);
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
            errorCallback();
          } 
        );
      }

      getInternalFlowByID(id: any, successCallback: any, errorCallback: any) {
        let currentAPIProperty = apiProperties.GET_INTERNAL_FLOW_BY_ID;
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", id), currentAPIProperty.method).subscribe(
            (res: any) => {
              Utils.loader('#page-loader', 'hide');
              successCallback(res);
            },
            (err: any) => {
              this.toastr.error((err.error || {}).message || 'Something went wrong!');
              Utils.loader('#page-loader', 'hide');
              errorCallback(err);
            }
          );
      }

      deleteInternalFlow(id: any, successCallback: any, errorCallback: any) {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.DELETE_INTERNAL_FLOW_BY_ID;
          this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", id), currentAPIProperty.method).subscribe(
            (res: any) => {
              Utils.loader('#page-loader', 'hide');
              successCallback(res);
            },
            (err: any) => {
              this.toastr.error((err.error || {}).message || 'Delete Failed');
              Utils.loader('#page-loader', 'hide');
              errorCallback(err);
            }
          );
      }

      getAPIFromSwaggerText(fileContent) {
        let swaggerText = fileContent.toString();
        if (swaggerText && swaggerText !== "") {
          let swaggerJSON = JSON.parse(swaggerText);
          let swaggerData = { definition: swaggerJSON };
          let currentAPIProperty = apiProperties.IMPORT_SWAGGER;
          Utils.loader('#page-loader', 'show');
          return new Promise((resolve, reject) => {
            this.apiUtilService.invokeCloudAPI(currentAPIProperty.path, currentAPIProperty.method, swaggerData).subscribe(
              (res: any) => {
                Utils.loader('#page-loader', 'hide');
                resolve(res.body);
              },
              (err: any) => {
                reject(err);
                Utils.loader('#page-loader', 'hide');
              }
            )
          })
    
        }
      }
    
      importAPI(req){
          let currentAPIProperty = apiProperties.IMPORT_API;
          Utils.loader('#page-loader', 'show');
          return new Promise((resolve, reject) => {
            this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, req).subscribe(
              (res: any) => {
                Utils.loader('#page-loader', 'hide');
                resolve(res.body);
              },
              (err: any) => {
                reject(err);
                Utils.loader('#page-loader', 'hide');
              }
            )
          })
      }
  }