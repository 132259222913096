import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';
import { ProcessListService } from './../service/process-list.service';
import { CommonService } from '../service/common.service';
import { DefaultTextCellRendererComponent } from './../data-grid/default-text-cell-renderer.component';
import { ProcessListActionsComponent } from './../data-grid/process-list-actions.component';
import { ProcessListPublicComponent } from './../data-grid/process-list-public.component';
import { DateTimeRendererComponent } from './../data-grid/date-time-renderer.component';
import { ApiUtilService } from '../service/api-util.service';
import { apiProperties } from '../utility/constants';
declare var $: any;

@Component({
  selector: 'ccp-process-list-landing',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.css']
})
export class ProcessListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public selectedRowObject: any= {};

  constructor(
    private apiUtilService: ApiUtilService,
    public processListService: ProcessListService,
    private toastr: ToastrService,
    private cs: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    document.title = "CCS - Process";
    this.initDataGridDetails();
    this.processListService.getAllProcessList();
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Process Name', field: 'name', sortable: true, width: 300, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Category', field: 'category', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Type', field: 'type', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Start Event', field: 'startEvent', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Access', field: 'isPublic', sortable: true, suppressMovable: true, width: 160, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, width: 150, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, width: 150, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 150, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      processListActionsComponent: ProcessListActionsComponent,
      processListPublicComponent: ProcessListPublicComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if (!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'processListActionsComponent';
            break;
          case 'Access':
            colDef.cellRenderer = 'processListPublicComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if (type === 'edit') {
      this.router.navigate(['landing', { outlets: { studio: 'process-studio/' + currentRowData['name'] }}]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } else if (type === 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.updatedName = this.selectedRowObject.name + " Clone";
      $('#clone-process-modal').modal('show');
    }
  }

  deleteProcessFlow() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_PROCESS_METADATA;
      this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method).subscribe(
        (res: any) => {
          this.toastr.success('Process Flow (' + this.selectedRowObject.name + ') Deleted Successfully');
          $('#delete-confirmation-modal').modal('hide');
          Utils.loader('#page-loader', 'hide');
          this.processListService.getAllProcessList();
        },
        (err: any) => {
          this.toastr.error((err.error || {}).message || 'Process Flow (' + this.selectedRowObject.name + ') Delete Failed');
          Utils.loader('#page-loader', 'hide');
          console.log(err);
        }
      );
  }

  cloneProcessFlow(navToEdit: boolean) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.CLONE_PROCESS_METADATA;
    var { description, category, type } = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method, { name: this.selectedRowObject.updatedName, description, category, type }).subscribe(
      (res: any) => {
        $('#clone-process-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        if(navToEdit) this.invokeGridAction({rowData: res.body, type: 'edit'});
        else this.processListService.getAllProcessList();
        this.toastr.success("Process Cloned Successfully.");
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

}