import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { apiProperties } from '../../utility/constants';
import { ApiUtilService } from '../../service/api-util.service';
import { AnalyticsStudioService } from '../../analytics-studio/analytics-studio.service';
import { CommonService } from '../../service/common.service';
import { Utils } from '../../utility/util';
//import { ReportGeneratorService } from '../service/report-generator.service';


declare var $: any;
@Component({
  selector: 'ccp-analytics-create-wizard',
  templateUrl: './analytics-create-wizard.component.html',
  styleUrls: ['./analytics-create-wizard.component.css']
})
export class AnalyticsCreateWizardComponent implements OnInit {

  @Output() createAnalytics = new EventEmitter<Event>();
  @Output() skipToStudio = new EventEmitter<any>();
  
  activeTab: string;
  tabsName = ['DATASOURCE', 'SPECIFICATION', 'CHARTTYPE', 'DIMENSIONS', 'CHARTNAME'];
  public currentDataSource;
  public chartConfig:any = {
    apiDetails:{objectName: "",apiId: ""},
    dbDetails: {type: 'Dynamic', objectName: "", alias: ""},
    analyticsType: "",
    name: "",
    description: "",
    returnField:[],
    graphAdditionalDetails:{xAxis: "", yAxis: "", series: "",zAxis: "", count: ""}
  }
  public datasourceObj: any;
  public datasourceObjAlias: any;
  public objectMetaData;
  chartActive:any = "";
  dataSources = [{icon:'database', title: 'Database', value: 'DATABASE', isAvaialble: true},
                {icon:'api', title: 'API', value: 'API', isAvaialble: true},
                {icon:'file', title: 'File', value: 'file', isAvaialble: false}, 
                {icon:'googleSheet', title: 'Google Sheets', value: 'googleSheet', isAvaialble: false},
                {icon:'pool', title: 'Pool', value: 'pool', isAvaialble: false},
                {icon:'onlineForm', title: 'Online Form', value: 'onlineForm', isAvaialble: false}
               ]
  charts:any = [{icon:'table', title:'Reports', value:'reports'},
                {icon:'floatBar', title:'Float Bar', value:'column'},
                {icon:'horizontal', title:'Horizontal', value:'bar'},
                {icon:'lollipop', title:'Lollipop', value:'lollipop'},
                {icon:'pie', title:'Pie Chart', value:'pie'},
                {icon:'donut', title:'Donut Chart', value:'donut'},
                {icon:'radialBar', title:'Radial Bar', value:'radialbar'},
                {icon:'line', title:'Line', value:'line'},
                {icon:'bubble', title:'Bubble', value:'bubble'},
                {icon:'streamGraph', title:'Stream Graph', value:'streamgraph'},
                {icon:'table', title:'Table', value:'table'},
                {icon:'gauge', title:'Gauge', value:'gauge'},
                {icon:'pyramid', title:'Pyramid Bar', value:'columnpyramid'},
                {icon:'funnel', title:'Funnel', value:'funnel'},
                {icon:'area', title:'Area Chart', value:'area'},
                {icon:'heatmap', title:'Heat Map', value:'heatmap'},
                {icon:'scatter', title:'Scatter', value:'scatter'},
                {icon:'candlestick', title:'Candlestick', value:''},
                {icon:'gauge', title:'Solid Gauge', value:'solidgauge'}
              ];

  constructor(private toastr: ToastrService, 
              //private rgs: ReportGeneratorService,
              private apiUtilService : ApiUtilService,
              private ats: AnalyticsStudioService) { }

  ngOnInit() {
  }

  getObjectMetadata() {
    let currentAPIProperty = apiProperties.GET_ALL_OBJECT_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ats.objectsList = res.body || [];
        this.ats.objectsMap = {};
        this.ats.objectsList.forEach((obj: any) => this.ats.objectsMap[obj.model] = obj);
      },
      (err: any) => console.error(err)
    );
  }

  create(apiList) {
    this.reset();
    this.getObjectMetadata();
    this.getInternalFlowMetadata();
    $("#createAnalyticsModal").modal("show");
  }

  onClickTab(tabName){
    this.activeTab = tabName;
  }

  selectDataSource(dataSource: any){
    this.currentDataSource = dataSource;
    this.chartConfig['type'] = dataSource.value;
    switch(dataSource.value){
      case "API":
        this.updateAPIDimensions();
        break;
      case "DATABASE":
        this.getDBTablesList();
        break;
      default: 
        break;
    }
  }

  selectChartType(data: any) {
    this.chartActive = data;
    this.chartConfig.analyticsType = data.value;
  }

  next(){
    try{
      const tabCount = this.tabsName.indexOf(this.activeTab);
      if(this.chartConfig.analyticsType === 'reports' || this.chartConfig.analyticsType === 'table'){
        this.activeTab = this.tabsName[tabCount+2];
      }else{
        this.activeTab = this.tabsName[tabCount+1];
      }
      
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  back(){
    try{
      const tabCount = this.tabsName.indexOf(this.activeTab);
      if(this.chartConfig.analyticsType === 'reports' || this.chartConfig.analyticsType === 'table'){
        this.activeTab = this.tabsName[tabCount-2];
      }else{
        this.activeTab = this.tabsName[tabCount-1];

      }
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  createChart(){
    this.createAnalyticsFromWizard(this.chartConfig);
    $("#createAnalyticsModal").modal("hide");
  }

  nextBtnEnabled(){
    switch(this.activeTab){
      case "DATASOURCE":
        if(this.chartConfig.type === undefined){
          return true;
        }        
      break;
      case "SPECIFICATION":
        if(this.chartConfig.type === 'API' && (this.chartConfig.apiDetails.objectName === "" || this.chartConfig.apiDetails.apiId === "" )){
          return true;
        }else if(this.chartConfig.type === 'DATABASE' && this.chartConfig.dbDetails.objectName === "" ){
          return true;
        }
      break;
      case "CHARTTYPE":
        if(this.chartConfig.analyticsType === "")
          return true;
      break;
      case "DIMENSIONS":
        if(this.chartConfig.analyticsType === 'pie' || this.chartConfig.analyticsType === 'donut' || this.chartConfig.analyticsType === 'funnel'){
          if(this.chartConfig.graphAdditionalDetails.series === undefined || this.chartConfig.graphAdditionalDetails.count === undefined || this.chartConfig.graphAdditionalDetails.series === "" || this.chartConfig.graphAdditionalDetails.count === "")
          return true;
        }else if(this.chartConfig.analyticsType === 'gauge' || this.chartConfig.analyticsType === 'solidgauge'){
          if(this.chartConfig.graphAdditionalDetails.count === undefined || this.chartConfig.graphAdditionalDetails.count === "")
          return true;
        }else{
          if((this.chartConfig.graphAdditionalDetails.xAxis === undefined || this.chartConfig.graphAdditionalDetails.yAxis === undefined || this.chartConfig.graphAdditionalDetails.xAxis === "" || this.chartConfig.graphAdditionalDetails.yAxis === ""))
          return true;
        }
        
      break;
      case "CHARTNAME":
        if(this.chartConfig.name === "" || this.chartConfig.description === "")
          return true;
      break;
    }
  }

  reset() {
    this.activeTab = "DATASOURCE";
    this.chartActive = "";
    this.chartConfig = {
      apiDetails:{objectName: "",apiId: ""},
      dbDetails: {type: 'Dynamic', objectName: "", alias: ""},
      analyticsType: "",
      name: "",
      description: "",
      returnField:[],
      graphAdditionalDetails:{xAxis: "", yAxis: "", series: "",zAxis: "", count: ""}
    }
  }

  getInternalFlowMetadata() {
    let currentAPIProperty = apiProperties.GET_ALL_INTERNAL_FLOWS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ats.internalFlowList = res.body || [];
        this.ats.internalFlowInputMap = {};
        this.ats.internalFlowList.forEach(api => {
          this.ats.internalFlowInputMap[api.input] = this.ats.internalFlowInputMap[api.input] || [];
          this.ats.internalFlowInputMap[api.input].push(api);
        });
        this.ats.internalFlowIDMap = {};
        this.ats.internalFlowList.forEach((api: any) => this.ats.internalFlowIDMap[api.idRef] = api);
      },
      (err: any) => console.error(err)
    );
  }

  getDBTablesList() {
    if(!this.ats.dbTablesList) {
      this.ats.dbTablesList = [];
      let currentAPIProperty = apiProperties.GET_REPORT_SCHEMA_TABLES;
      this.apiUtilService.invokeCloudAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
        (res: any) => {
          this.ats.dbTablesList = res.body || [];
          this.getDBColumnDetails();
        },
        (err: any) => {
          console.error(err);
        }
      );
    } else this.getDBColumnDetails();
  }

  getDBColumnDetails() {
    var tableName = this.chartConfig.dbDetails.objectName;
    if(tableName) {
      if(this.ats.dbColumnDetails[tableName]) return this.updateDBDimensions();
      else {
        let currentAPIProperty = apiProperties.GET_REPORT_SCHEMA_TABLE_COLUMNS;
        this.apiUtilService.invokeCloudAPI(currentAPIProperty.path + '/' + tableName, currentAPIProperty.method).subscribe(
          (res: any) => {
            this.ats.dbColumnDetails[tableName] = res.body || [];
            this.updateDBDimensions();
          },
          (err: any) => {
            console.error(err);
          }
        );
      }
    } else this.updateDBDimensions();
  }

  updateDBDimensions() {
    var tableName = this.chartConfig.dbDetails.objectName;
    var aliasName = this.chartConfig.dbDetails.alias;
    aliasName = aliasName ? aliasName + "." : "";
    this.ats.analyticsReturnField = (this.ats.dbColumnDetails[tableName] || []).map((obj: any) => {
      return { name: obj.Field, attr: aliasName + obj.Field };
    });
    if(this.ats.analyticsReturnField.length != 0)
      this.chartConfig.returnField = this.ats.analyticsReturnField;
  }

  updateAPIDimensions() {
    var inetrnalFlow = this.ats.internalFlowIDMap[this.chartConfig.apiDetails.apiId] || {};
    this.ats.analyticsReturnField = ((this.ats.objectsMap[inetrnalFlow.output] || {}).attributes || []).map((obj: any) => {
      return { name: obj.name, attr: obj.name };
    });
    if(this.ats.analyticsReturnField.length != 0)
      this.chartConfig.returnField = this.ats.analyticsReturnField;
  }

  createAnalyticsFromWizard(createWizardMetadata: any) {
    let reqObj = this.getAnalyticsReqObj(createWizardMetadata);
    this.ats.setCurrentAnalyticsMetadata(reqObj);
    this.ats.save(true);
  }

  getAnalyticsReqObj(createWizardMetadata: any) {
    var { name, description, type, analyticsType, graphAdditionalDetails, apiDetails, dbDetails, returnField } = createWizardMetadata;
    var analyticsDetails: any = { 
      name,
      description,
      analyticsType,
      metadata: { type, returnField, config: { graphAdditionalDetails } }
    };
    if (type == "DATABASE") analyticsDetails.metadata["dbDetails"] = { type: "Dynamic", dataSource: [{ objectName: dbDetails.objectName, alias: dbDetails.alias }] };
    else if(type == "API") analyticsDetails.metadata["apiDetails"] = apiDetails;

    if (analyticsType === 'table') analyticsDetails.reportType = "Data Grid";
    else if (analyticsType === 'reports') analyticsDetails.reportType = "HTML Reports";
    else analyticsDetails.reportType = "Graphical";

    var analyticsTypeKeyRef = {"Data Grid": "grid", "HTML Reports": "htmlReport", "Graphical": "graphical"};
    analyticsDetails.metadata.config[analyticsTypeKeyRef[analyticsDetails.reportType]] = Utils.cloneJSON(this.ats.basicChartInfo[analyticsType].additionalInfo);
    
    return analyticsDetails;
  }
  
}
