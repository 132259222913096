import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../utility/util';
import { ApiUtilService } from '../service/api-util.service';
import { CommonService } from '../service/common.service';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { EventSchemaListActionsComponent } from '../data-grid/event-schema-list-actions.component';
import { DateTimeRendererComponent } from '../data-grid/date-time-renderer.component';
import { apiProperties } from '../utility/constants';
import { ProcessListService } from './../service/process-list.service';
declare var $: any;

@Component({
  selector: 'ccp-event-schema-list',
  templateUrl: './event-schema-list.component.html',
  styleUrls: ['./event-schema-list.component.css']
})
export class EventSchemaListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public eventSchemaList: any = [];
  public selectedRowObject: any= {};
  
  constructor(
    public processListService: ProcessListService,
    private apiUtilService: ApiUtilService,
    private toastr: ToastrService,
    private router: Router,
    private cs: CommonService
  ) { }

  ngOnInit() {
    document.title = "CCS - Event Schema";
    this.initDataGridDetails();
    this.getAllEventSchema();
    this.processListService.getAllProcessList();
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Process Name', field: 'processName', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color'  },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color'  },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color'  },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      eventSchemaListActionsComponent: EventSchemaListActionsComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'eventSchemaListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if(type === 'edit'){
      this.router.navigate(['landing', { outlets: { studio: 'event-schema-studio/' + currentRowData['name'] }}]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } else if (type === 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.updatedName = this.selectedRowObject.name + " Clone";
      $('#clone-event-schema-modal').modal('show');
    }
  }

  getAllEventSchema() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_EVENT_SCHEMA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.eventSchemaList = res.body || [];
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  deleteEventSchema() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_EVENT_SCHEMA_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method).subscribe(
      (res: any) => {
        this.toastr.success('Event Schema (' + this.selectedRowObject.name + ') Deleted Successfully');
        $('#delete-confirmation-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        this.getAllEventSchema();
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Event Schema (' + this.selectedRowObject.name + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

  cloneEventSchema(navToEdit: boolean) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.CLONE_EVENT_SCHEMA;
    var { processName } = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method, { name: this.selectedRowObject.updatedName, processName }).subscribe(
      (res: any) => {
        $('#clone-event-schema-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        if(navToEdit) this.invokeGridAction({rowData: res.body, type: 'edit'});
        else this.getAllEventSchema();
        this.toastr.success("Event Schema Cloned Successfully.");
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

}