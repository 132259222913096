import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Pipe, PipeTransform, ElementRef, ViewChild } from '@angular/core';
import { ProcessStudioService } from '../service/process-studio.service';
import { EventApiService } from '../service/event-api.service';
import { CommonService } from '../service/common.service';
import { ApiUtilService } from '../service/api-util.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';
import * as uuid from 'uuid';
declare var $: any;

@Component({
  selector: 'ccp-process-studio-canvas',
  templateUrl: './process-studio-canvas.component.html',
  styleUrls: ['./process-studio-canvas.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessStudioCanvasComponent implements OnInit{

  @ViewChild('opSearchInput', { static: false }) opSearchInput: ElementRef<any>;
  
  public apiSettings: any = {};
  public processName: string;
  public isCreateFlow: boolean = false;
  private isProcessFlowRetrieved: boolean = false;
  public saveProcessObject: any = {};
  public showOpSearchInput: boolean = false;
  public searchOpValue: string = '';
  public processList: any;
  public isPublic: boolean = false;
  public queryKeysRef: any = {};
  public apiParamsRef: any[] = [];
  public activeProcessSettingsTab: string;

  public eventAPIListContRendered: boolean = false;
  public showCanvasContainer: boolean = false;

  constructor(
    private apiUtilService: ApiUtilService,
    public eventApiService: EventApiService,
    private cs: CommonService,
    public processStudioService: ProcessStudioService, 
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    if($('#sidebar').hasClass('active') == false) {
      $('#sidebar').addClass('active');
      $('.studio-wrapper').addClass('full-width');
    }
    this.route.paramMap.subscribe(paramObject => {
      this.processName = (paramObject['params']['name']) || '';
    });
  }

  ngOnInit(): void {
    this.showCanvasContainer = true;
    setTimeout(() => {
      document.title = "CCS - Process";
      this.apiSettings = this.eventApiService.apiSettings;
      this.apiSettings.closeAPISettings();
      this.processStudioService.decisionAttrsObs.subscribe((userDetails) => {
        this.processStudioService.currentDecisionAttrs = userDetails;
        this.ref.detectChanges();
      });
      if(this.processName){
        this.isCreateFlow = false;
        this.processStudioService.getProcessMetadataByName(this.processName, () => {
          this.cs.updateMenuBreadCrumbDetails({ label: this.processName, parentListComponentURL: 'process-list'});
          this.isProcessFlowRetrieved = true;
          this.drawCanvas();
        });
      } else {
        this.isCreateFlow = true;
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'process-list'});
        this.processStudioService.processMetadata = { startEvent: "", baseProcessName: "" };
        this.processName = 'new';
      }
      Utils.loader('#api-list-cont-loader', 'show');
      this.eventApiService.getAllEventSchemaList((res: any) => {
        Utils.loader('#api-list-cont-loader', 'hide');
      }, (err: any) => console.error(err));
      this.getAllProcessList();
    }, 0);
  }

  openProcessSettingsModal() {
    this.activeProcessSettingsTab = "GENERAL";
    var canvasMetadata = Utils.cloneJSON(this.processStudioService.processMetadata.metadata || {});
    this.queryKeysRef = canvasMetadata.queryKeys || {};
    this.apiParamsRef = canvasMetadata.apiParameters || [];
    $("#processSettingsModal").modal("show");
  }

  saveProcessSettingsModal() {
    this.apiParamsRef = this.apiParamsRef.filter((params: any) => params.name || params.type || params.value);
    var isValidateSettings = () => {
      var invalidAPIParams = this.apiParamsRef.find((params: any) => !params.name || !params.type || !params.value);
      return !invalidAPIParams;
    };
    if(!this.processStudioService.processMetadata.startEvent) {
      this.activeProcessSettingsTab = "GENERAL";
      this.toastr.error("Start Event is mandatory");
    } else if(isValidateSettings()) {
      var canvasMetadata = this.processStudioService.processMetadata.metadata = this.processStudioService.processMetadata.metadata || {};
      canvasMetadata.queryKeys = Utils.cloneJSON(this.queryKeysRef);
      canvasMetadata.apiParameters = Utils.cloneJSON(this.apiParamsRef);
      this.toastr.success("Applied successfully");
      $("#processSettingsModal").modal("hide");
    } else {
      this.activeProcessSettingsTab = "API-PARAMETERS";
      this.toastr.error("Please fill out the empty fields");
    }
  }

  addAPIParams() {
    this.apiParamsRef.push({ name: "", type: "", value: "" });
  }

  deleteAPIParams(idx: number) {
    this.apiParamsRef.splice(idx, 1);
  }

  checkPublic(data: any) {
    if(data == "Public"){
      this.isPublic = true;
    } else {
      this.isPublic = false;
    }   
  }

  getAllProcessList() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_PROCESS_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.processList = res.body;
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  onEventAPIListLoaded() {
    this.eventAPIListContRendered = true;
    this.ref.detectChanges();
    this.processStudioService.initProcessStudioCanvas();
    this.drawCanvas();
  }

  drawCanvas() {
    if(this.eventAPIListContRendered && (this.isCreateFlow || this.isProcessFlowRetrieved)){
      let isPublic = this.processStudioService.processMetadata.isPublic;
      if(isPublic){
        this.isPublic = true;
      } else {
        this.isPublic = false;
      }
      var metadata = this.processStudioService.processMetadata.metadata || {};
      if(metadata.steps && !metadata.shapeList){
        metadata.shapeList = this.buildShapeList(metadata.steps);
      }
      this.processStudioService.writeDataToCanvas(metadata.shapeList || []);
      Utils.loader('#page-loader', 'hide');
    }
    
  }

  buildShapeList(steps: any){
    var shapeList = [];
    var arrowList = [];
    var previosId = null;
    var x=50, y=50;
    steps.forEach((step: any) => {
      var id = uuid.v4();
      shapeList.push({
        x,
        y,
        id,
        "type": "ProcessStudio.ProcessFigure",
        "userData": {
          "name": step.name,
          "events": step.events,
          "apiName": step.name,
          "description": step.description
        }
      });
      x += 300;
      if(x > 1000){
        x=50, y+=200;
      }
      if(previosId != null){
        arrowList.push({
          "id": uuid.v4(),
          "type": "ProcessStudio.HoverConnection",
          "source": {
              "node": previosId,
              "port": "output0"
          },
          "target": {
              "node": id,
              "port": "input0"
          }
        });
      }
      previosId = id;
    });
    return shapeList.concat(arrowList);
  }

  getProcessFlowData(callback: any){
    this.processStudioService.getDataFromCanvas((shapeList: any) => {
      try{
        var stepMap = {};
        var sourcePortMap = {};
        var targetPortMap = {};
        var updatedShapeList = [];
        shapeList.forEach(function(obj: any){
            if(obj.type == "ProcessStudio.ProcessFigure"){
                stepMap[obj.id] = obj;
                updatedShapeList.push({
                  id: obj.id,
                  type: obj.type,
                  userData: obj.userData,
                  x: obj.x,
                  y: obj.y
                });
            }else  if(obj.type == "ProcessStudio.HoverConnection"){
              sourcePortMap[obj.source.node] = (sourcePortMap[obj.source.node] || []).concat([obj.target.node]);
              targetPortMap[obj.target.node] = (targetPortMap[obj.target.node] || []).concat([obj.source.node]);
              updatedShapeList.push({
                id: obj.id,
                type: obj.type,
                source: { node: obj.source.node, port: obj.source.port },
                target: { node: obj.target.node, port: obj.target.port }
              });
            }
        });

        if(Object.keys(stepMap).length == 0) throw 'No Step available to save';
        
        // Validate is any flow is not conected
        var inputPortList = Object.keys(stepMap);
        Object.keys(sourcePortMap).forEach((sourcePort: string) => {
          var targetPortList = sourcePortMap[sourcePort];
          if(targetPortList.length >= 2) throw 'Multi flow not supporting';
          targetPortList.forEach((targetPort: string) => {
            var inputPortIndex = inputPortList.indexOf(targetPort);
            if(inputPortIndex != -1) inputPortList.splice(inputPortIndex, 1);
          });
        });
        if(inputPortList.length == 0) throw 'No start step found';
        if(inputPortList.length >= 2) throw 'All the step should be connected';
        
        var sequence = 0;
        var steps = [];
        var shapeId = inputPortList[0];
        do{
          var userData = stepMap[shapeId].userData;
          steps.push({
            "sequence": sequence ++,
            "name": userData.name,
            "description": userData.description || "",
            "events": userData.events
          });
          shapeId = (sourcePortMap[shapeId] || {})[0];
        }while(shapeId != null);
        callback(null, { steps, shapeList: updatedShapeList });
      }catch(error){
        callback(error);
      }
    });
  }

  saveOrUpdateProcessFlow(){
    if(this.isCreateFlow){
      this.getProcessFlowData((error: any, metadata: any) => {
        if(error){
          this.toastr.error(error.message || error);
        }else{
          metadata = Utils.angularMerge(this.processStudioService.processMetadata.metadata || {}, metadata);
          this.saveProcessObject = { metadata };
          $("#saveProcessFlowModal").modal('show');
        }
      });
    }else{
      this.updateProcessFlow();
    }
  }

  saveProcessFlow() {
    var isValidSaveProcessObject = () => {
      try {
        if((this.saveProcessObject.name || '').trim() == '') throw 'Process Name is mandatory';
        if((this.saveProcessObject.desc || '').trim() == '') throw 'Process Description is mandatory';
        if((this.saveProcessObject.category || '').trim() == '') throw 'Process Catagory is mandatory';
        if((this.saveProcessObject.type || '').trim() == '') throw 'Process Type is mandatory';
        if((this.processStudioService.processMetadata.startEvent || '').trim() == '') throw 'Start Event is mandatory';
        let isExist = this.processList.find((api: any) => api.name === this.saveProcessObject.name);
        if(isExist) throw 'process name already exists';
        return true;
      } catch (error) {
        this.toastr.error(error);
        return false;
      }
    }
    if(isValidSaveProcessObject()) {
      Utils.loader('#page-loader', 'show');
      var requestData = {
        "name": this.saveProcessObject.name,
        "description": this.saveProcessObject.desc,
        "metadata": this.saveProcessObject.metadata,
        "category": this.saveProcessObject.category,
        "type": this.saveProcessObject.type,
        "baseProcessName": this.processStudioService.processMetadata.baseProcessName,
        "startEvent": this.processStudioService.processMetadata.startEvent,
        "isPublic": this.isPublic
      };
      let currentAPIProperty = apiProperties.SAVE_PROCESS_METADATA;
      this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, requestData).subscribe(
        (res: any) => {
          this.isCreateFlow = false;
          this.isProcessFlowRetrieved = true;
          this.processStudioService.processMetadata = res.body;
          this.toastr.success('Process Flow Saved Successfully');
          this.router.navigate(['landing', { outlets: { studio: 'process-studio/' + requestData.name }}]);
          $("#saveProcessFlowModal").modal('hide');
          Utils.loader('#page-loader', 'hide');
        },
        (err: any) => {
          this.toastr.error((err.error || {}).message || 'Save Failed');
          Utils.loader('#page-loader', 'hide');
          console.log(err);
        }
      );
    } else {
      return;
    }
  }
 
  updateProcessFlow() {
    Utils.loader('#page-loader', 'show');
    this.getProcessFlowData((error: any, metadata: any) => {
      if(error){
        this.toastr.error(error.message || error);
        Utils.loader('#page-loader', 'hide');
      }else{
        var processMedatata = this.processStudioService.processMetadata;
        processMedatata.metadata.steps = metadata.steps;
        processMedatata.metadata.shapeList = metadata.shapeList;
        var requestData = {
          ...processMedatata,
          "name": processMedatata.name,
          "description": processMedatata.description,
          "metadata": processMedatata.metadata,
          "startEvent": processMedatata.startEvent,
          "baseProcessName": processMedatata.baseProcessName,
          "category": processMedatata.category,
          "type": processMedatata.type,
          "isPublic": this.isPublic
        };
        let currentAPIProperty = apiProperties.UPDATE_PROCESS_METADATA;
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", processMedatata.name), currentAPIProperty.method, requestData).subscribe(
          (res: any) => {
            this.toastr.success('Process Flow Updated Successfully');
            Utils.loader('#page-loader', 'hide');
          },
          (err: any) => {
            this.toastr.error((err.error || {}).message || 'Update Failed');
            Utils.loader('#page-loader', 'hide');
            console.log(err);
          }
        );
      }
    });

  }

  toggleOpSearchInput() {
    if(this.searchOpValue === '') this.showOpSearchInput = false;
  }

  triggerOpSearchInput() {
    this.showOpSearchInput = !this.showOpSearchInput;
    if(this.showOpSearchInput) setTimeout(() => this.opSearchInput.nativeElement.focus(), 0);
  }

}

@Pipe({ name: 'operationFilterPipe' })
export class OperationFilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it['name'].toLocaleLowerCase().includes(searchText);
    });
  }
}