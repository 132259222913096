import { Component, OnInit } from '@angular/core';
import { EventApiService } from './../service/event-api.service';
import { CommonService } from './../service/common.service';
import { InternalFlowService } from './../service/internal-flow.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ccp-internal-flow-studio',
  templateUrl: './internal-flow-studio.component.html',
  styleUrls: ['./internal-flow-studio.component.css']
})
export class InternalFlowStudioComponent implements OnInit {
  eventApiInput: string;
  apiSettings: any;

  constructor(
    private eventApiService: EventApiService,
    private internalFlowService: InternalFlowService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private cs: CommonService
  ) { 
    this.apiSettings = this.eventApiService.apiSettings;
    this.route.paramMap.subscribe(paramObject => {
      this.eventApiInput = (paramObject['params']['id']) || '';
    });
  }

  ngOnInit() {
    document.title = "CCS - APIs";
    this.internalFlowService.getAllInternalFlows((res: any) => {
      if(this.eventApiInput) {
        this.apiSettings.isCreateFlow = false;
        var currentInternalFlowName = (res.body.find((api: any) => api.id === this.eventApiInput) || {}).name;
        if(currentInternalFlowName) {
          this.cs.updateMenuBreadCrumbDetails({ label: currentInternalFlowName, parentListComponentURL: 'internal-flow-list'});
          this.apiSettings.init({isCallFrmProcessFlow: false, id: this.eventApiInput, forceDbCall: true});
        } else {
          this.toastr.error("API does not exist.");
          this.router.navigate(['landing', { outlets: { studio: 'internal-flow-list' } }]);
        }
        
      }else{
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'internal-flow-list'});
        this.apiSettings.initCreateNewEventAPI();
      }
    }, (err: any) => {
        console.error(err);
    });
  }

}
