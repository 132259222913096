import { Component, Input, OnInit } from '@angular/core';
import { ObjectModelerService } from './../service/object-modeler.service';
import { CommonService } from '../service/common.service';
import { ObjectAttributeCellRendererComponent } from './../data-grid/object-attr-cell-renderer.component';
import { ObjectRelationshipCellRendererComponent } from './../data-grid/object-rels-cell-renderer.component';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from '../utility/util';
declare var $: any;

@Component({
  selector: 'ccp-object-modeler-editor',
  templateUrl: './object-modeler-editor.component.html',
  styleUrls: ['./object-modeler-editor.component.css']
})
export class ObjectModelerEditorComponent implements OnInit {

  @Input() isBulkCreateAPIRenderer: boolean;
  private currentObjectId: string;
  public isCreateFlow: boolean = false;

  private attrGridApi: any;
  public attrGridPageSize: number = 10;
  public attrGridSearchText: string = '';
  public attrGridColumnDefs: any[];
  public attrGridFrameworkComponents: object;

  private relsGridApi: any;
  public relsGridPageSize: number = 10;
  public relsGridSearchText: string = '';
  public relsGridColumnDefs: any[];
  public relsGridFrameworkComponents: object;

  public currentActiveGrid: string = 'ATTRIBUTE';

  public context: any;

  public selectedRowObject: any= {};

  constructor(public oms: ObjectModelerService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute, private cs: CommonService) {
    this.context = { componentParent: this };
    this.attrGridFrameworkComponents = { objectAttributeCellRendererComponent: ObjectAttributeCellRendererComponent };
    this.relsGridFrameworkComponents = { objectRelationshipCellRendererComponent: ObjectRelationshipCellRendererComponent };
    this.route.paramMap.subscribe(paramObject => {
      this.currentObjectId = (paramObject['params']['id']) || '';
    });
  }

  ngOnInit() {
    document.title = "CCS - Objects";
    Utils.loader('#page-loader', 'show');
    this.isBulkCreateAPIRenderer = this.isBulkCreateAPIRenderer || false;
    this.oms.getAllObjects((res: any) => {
      if(this.currentObjectId) {
        this.oms.currentEditObject = this.oms.objectsList.find((obj: any) => obj.id === this.currentObjectId);
        if (this.oms.currentEditObject) {
          this.cs.updateMenuBreadCrumbDetails({ label: this.oms.currentEditObject.model, parentListComponentURL: 'object-list' });
        } else {
          this.toastr.error("Object does not exist.");
          this.router.navigate(['landing', { outlets: { studio: 'object-list' } }]);
        }
      }else{
        this.isCreateFlow = true;
        if(!this.isBulkCreateAPIRenderer)
          this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'object-list'});
        this.oms.currentEditObject = {
          "model": this.isBulkCreateAPIRenderer ? "" : "NewObject" + (this.oms.objectsList.length + 1),
          "nextId": 2,
          "nextRelationId": 1,
          "attributes": [{
            "name": "id",
            "type": "Integer",
            "size": 10,
            "key": "primary",
            "id": 1,
            "format": "default",
            "type_format": "autonumber",
            "indexed": true,
            "isPrivate": false,
            "isAuditField": false
          }],
          "relationships": [],
        };
        this.oms.objectsList.push(this.oms.currentEditObject);
      }
      this.initDataGridDetails(this.oms.currentEditObject);
      Utils.loader('#page-loader', 'hide');
    },(err: any) => {
      Utils.loader('#page-loader', 'hide');
      console.error(err);
    });
  }

  initDataGridDetails(currentEditObject) {
    if(currentEditObject.isSystemObject){
      this.attrGridColumnDefs = [
        { headerName: "Attribute", field: "name", cellRenderer: "objectAttributeCellRendererComponent", width: 450, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) {
          var key = params.event.key;
          //return key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter';
          return true;
        }},
        { headerName: 'Label', field: "label", cellRenderer: "objectAttributeCellRendererComponent", width: 500, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: 'Type', field: "type_format", cellRenderer: "objectAttributeCellRendererComponent", width: 330, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: 'Size', field: "size", cellRenderer: "objectAttributeCellRendererComponent", width: 150, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; }},
        { headerName: 'Private', field: "isPrivate", cellRenderer: "objectAttributeCellRendererComponent", width: 150, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: 'Required', field: "mandatory", cellRenderer: "objectAttributeCellRendererComponent", width: 150, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: 'Indexed', field: "indexed", cellRenderer: "objectAttributeCellRendererComponent", width: 150, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: 'Audit', field: "isAuditField", cellRenderer: "objectAttributeCellRendererComponent", width: 150, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } }
      ];
      this.relsGridColumnDefs = [
        { headerName: "Object", field: 'model', cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: "Name", field: 'name', cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
        { headerName: "Type", field: 'type', cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } }
      ];
    } else {
      this.attrGridColumnDefs = [
      { headerName: "Attribute", field: "name", cellRenderer: "objectAttributeCellRendererComponent", width: 450, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) {
        var key = params.event.key;
        //return key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter';
        return true;
      }},
      { headerName: 'Label', field: "label", cellRenderer: "objectAttributeCellRendererComponent", width: 500, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: 'Type', field: "type_format", cellRenderer: "objectAttributeCellRendererComponent", width: 350, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: 'Size', field: "size", cellRenderer: "objectAttributeCellRendererComponent", width: 200, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; }},
      { headerName: 'Private', field: "isPrivate", cellRenderer: "objectAttributeCellRendererComponent", width: 250, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: 'Required', field: "mandatory", cellRenderer: "objectAttributeCellRendererComponent", width: 280, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: 'Indexed', field: "indexed", cellRenderer: "objectAttributeCellRendererComponent", width: 250, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: 'Audit', field: "isAuditField", cellRenderer: "objectAttributeCellRendererComponent", width: 250, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: 'Action', cellRenderer: "objectAttributeCellRendererComponent", sortable: false, width: 250, suppressMovable: true }
    ];
    this.relsGridColumnDefs = [
      { headerName: "Object", field: 'model', cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "Name", field: 'name', cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "Type", field: 'type', cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "Action", cellRenderer: "objectRelationshipCellRendererComponent", sortable: false, width: 170, suppressMovable: true }
    ];
    }
    
  }

  onGridReady(params: any, gridAPIVar: string, pageSize: any) {
    this[gridAPIVar] = params.api;
    this[gridAPIVar].paginationSetPageSize(Number(pageSize));
    this.setGridLayout(gridAPIVar);
  }

  setGridLayout(gridAPIVar: string) {
    setTimeout(() => {
      this[gridAPIVar].sizeColumnsToFit();
      this[gridAPIVar].setDomLayout("autoHeight");
      if(gridAPIVar == 'attrGridApi') this.cs.updateCurrentGridAPI(this.attrGridApi);
      else  this.cs.updateCurrentGridAPI(this.relsGridApi);
    }, 100);
  }

  onPageSizeChanged(gridAPIVar: string, pageSize: any) {
    this[gridAPIVar].paginationSetPageSize(Number(pageSize));
  }

  onFilterTextBoxChanged(gridAPIVar: string, searchTextVar: string) {
    this[gridAPIVar].setQuickFilter(searchTextVar);
  }

  validateModelName(curEvent: Event, curModelName: string){
    var curInputDom = $(curEvent.target);
    var objectModels = this.oms.objectsList;
    var objectModelList = objectModels.filter((objects: any) => objects.model == curModelName );
    var objectModelListIgnoreCase = objectModels.filter((objects: any) => (objects.model || "").toLowerCase() == (curModelName || "").toLowerCase() );
    if(curModelName == ""){
      this.toastr.error('Field cannot be empty');
      curInputDom.addClass("border-warning");
      this.oms.currentEditObject.isObjectNameExists_ignoreCase = false;
      return;
    } else if(/^[a-zA-Z0-9_$@]*$/.test(curModelName) == false) {
      this.toastr.error('Remove white space or special characters except "_", "$", "@"');
      curInputDom.addClass("border-warning");
      this.oms.currentEditObject.isObjectNameExists_ignoreCase = false;
      return;
    } else if(objectModelList.length > 1){
      this.toastr.error('Object Name already exists');
      curInputDom.addClass("border-warning");
      this.oms.currentEditObject.isObjectNameExists_ignoreCase = false;
      return;
    } else if(objectModelListIgnoreCase.length > 1){
      curInputDom.removeClass("border-warning");
      this.oms.currentEditObject.isObjectNameExists_ignoreCase = true;
      return;
    } else {
      curInputDom.removeClass("border-warning");
      this.oms.currentEditObject.isObjectNameExists_ignoreCase = false;
      return;
    }
  }

  addAttr() {
    let newAttr: object = {
      "name": "",
      "type": "",
      "type_format": "",
      "size": "",
      "id": this.oms.currentEditObject.nextId,
      "format": "default",
      "isPrivate": false
    };
    (this.oms.currentEditObject["nextId"])++
    this.oms.currentEditObject.attributes.push(newAttr);
    this.attrGridApi.setRowData(this.oms.currentEditObject.attributes);
  }

  deleteAttr(rowIndex: number) {
    this.oms.currentEditObject.attributes.splice(rowIndex, 1);
    if(this.oms.currentEditObject.attributes.length == 0){
      let newAttr: any = {
               "name":"",
               "type":"",
               "size":"",
               "id": this.oms.currentEditObject.nextId,
               "format":"default"
             };
      (this.oms.currentEditObject["nextId"])++
      this.oms.currentEditObject.attributes.push(newAttr);
    }
    this.attrGridApi.setRowData(this.oms.currentEditObject.attributes);
  }

  addEnum(selectedRowIndex: number) {
    var curEnumList = this.oms.selectedAttribute.enumList;
    if (selectedRowIndex === curEnumList.length) curEnumList.push({value: ''});
  }

  deleteEnum(curIndex: number) {
    var curEnumList = this.oms.selectedAttribute.enumList;
    this.oms.selectedAttribute.enumList.splice(curIndex, 1);
    if (this.oms.selectedAttribute.enumList.length == 0) curEnumList.push({value: ''});
  }

  closeEnum() {
    setTimeout(() => {
      this.oms.selectedAttribute.enumList = this.oms.prevEnumList;
    }, 5);
  }

  saveEnum() {
    this.oms.selectedAttribute.enumList = this.oms.selectedAttribute.enumList.filter((obj: any) => obj.value.trim() != "" );
    this.toastr.success("Successfully applied");
  }

  addRelationship() {
    var emptyRelName = false;
    this.oms.currentEditObject.relationships.forEach((rel: any, index: number) => {
      if (rel.model === "") {
        $('#objectRelation_' + index).addClass("border-warning");
        this.toastr.error("Field cannot be empty");
        emptyRelName = true;
      }
    });
    if (!emptyRelName) {
      let newRelObj: object = {
        "model": "",
        "type": "1",
        "name": "Name",
        "id": this.oms.currentEditObject.nextRelationId
      };
      (this.oms.currentEditObject["nextRelationId"])++;
      this.oms.currentEditObject.relationships.push(newRelObj);
      this.relsGridApi.setRowData(this.oms.currentEditObject.relationships);
    }
  }

  deleteRelationship(rowIndex: number){
    this.oms.currentEditObject.relationships.splice(rowIndex, 1);
    this.relsGridApi.setRowData(this.oms.currentEditObject.relationships);
  }

}