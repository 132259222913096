import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-recently-edited',
  templateUrl: './recently-edited.component.html',
  styleUrls: ['./recently-edited.component.css']
})
export class RecentlyEditedComponent implements OnInit {

  @Output() invokeActionEvent = new EventEmitter<{type: string, rowData: any}>();
  @Input() records;
  @Input() iconDetails;

  constructor() { }

  ngOnInit() {
  }

  getRecentlyEditedList(){
    var test = (this.records || []).sort((a, b) => {
      var date1 = new Date(a["updatedAt"]).getTime();
      var date2 = new Date(b["updatedAt"]).getTime();
      if(date1 == date2) return 0;
      else if(date1 > date2) return -1;
      else if(date1 < date2) return 1;
    }).slice(0, 5);
    return test;
  }

  edit(rowData){
    this.invokeActionEvent.emit({type: "edit", rowData});
  }

  delete(rowData){
    this.invokeActionEvent.emit({type: "delete", rowData});
  }

}
