export function _get(
  obj: any,
  path: string | string[],
  defaultValue?: any
): any {
  if (!obj) return defaultValue as any;
  const paths = Array.isArray(path) ? path : path.split(".");
  const key = paths.splice(0, 1)[0];
  const value = obj.hasOwnProperty(key) ? obj[key] : undefined;
  return paths.length === 0
    ? value
    : value && paths[0]
      ? _get(value, paths)
      : defaultValue;
}

export function isEmptyValue(value: any): boolean {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
}