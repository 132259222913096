import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DefaultTextCellRendererComponent } from 'src/app/data-grid/default-text-cell-renderer.component';
import { MonitorLogsActionsComponent } from 'src/app/data-grid/monito-logs-actions.component';
import { MonitorLogsDateTimeRendererComponent } from 'src/app/data-grid/monitor-logs-date-time-renderer.component';
import { ApiUtilService } from 'src/app/service/api-util.service';
import { CommonService } from 'src/app/service/common.service';
import { MonitorLogsService } from 'src/app/service/monitor-logs.service';
import { Utils } from 'src/app/utility/util';
declare var $: any;

@Component({
  selector: 'app-process-log',
  templateUrl: './process-log.component.html',
  styleUrls: ['./process-log.component.css']
})
export class ProcessLogComponent implements OnInit {
 
  private actionButtonName: string = "View Trace";
  private selectedScreen = 'main';
  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public selectedEnvironment: any;
  public selectedEnvironmentObj = null;
  public selectedProcessId: any;
  public selectedStepObject: any = {};
  processContextString: string = "{}";
  showDetailsScreen: boolean;
  private eventHierarchyList = [];
  
  isDirectMonitorLogHit: boolean = false;

  constructor(
    private apiUtilService: ApiUtilService,
    public monitorLogsService: MonitorLogsService,
    private toastr: ToastrService,
    private cs: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
    this.route.paramMap.subscribe(paramObject => {
      this.selectedProcessId = (paramObject['params']['processId']) || '';
    });
    this.selectedEnvironment = this.route.snapshot.queryParamMap.get("env");
    this.monitorLogsService.setSelectedEnvRef(this.selectedEnvironment);
  }

  ngOnInit() {
    if(this.router.url.startsWith('/landing/(studio:monitorLogs')) {
      this.isDirectMonitorLogHit = true;
      if($('#sidebar').hasClass('active') == false) {
        $('#sidebar').addClass('active');
        $('.studio-wrapper').addClass('full-width');
        $('#sidebar > .custom-menu').css('display', 'none');
        $(".studio-wrapper .rowHeader .top-menu-breadcrumb").css('display', 'none');
        $(".studio-wrapper .rowHeader .user-access").css('visibility', 'hidden');
      }
    }
    document.title = "CCS - Process";
    this.initDataGridDetails();
    this.monitorLogsService.getProcessDetailsById(this.selectedProcessId, () => {
      ["createdAt", "updatedAt", "scheduledAt"].forEach(key => this.monitorLogsService.formatDateString(this.monitorLogsService.processDetails, key, key + "Formatted"));
      this.processContextString = JSON.stringify(this.monitorLogsService.processDetails.processContext, null, 3);
      this.showDetailsScreen = true;
      if((this.monitorLogsService.environmentList || []).length == 0){
        this.monitorLogsService.getEnvironmentList(() => {
          this.selectedEnvironmentObj = this.monitorLogsService.environmentList.find(obj => obj.environmentKey == this.selectedEnvironment);
        });
      } else {
        this.selectedEnvironmentObj = this.monitorLogsService.environmentList.find(obj => obj.environmentKey == this.selectedEnvironment);
      }
      this.monitorLogsService.getProcessLogDetails(this.selectedProcessId, () => {
        this.eventHierarchyList = [];
        for(let i = 0; i < this.monitorLogsService.processLogDetails.length; i++) {
          var obj = this.monitorLogsService.processLogDetails[i];
          if(i == 0 || obj.eventName != this.eventHierarchyList[this.eventHierarchyList.length - 1].eventName) {
            this.eventHierarchyList.push({eventName: obj.eventName, eventId: obj.eventId});
          }
        }
      });
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Step Id', field: 'stepId', sortable: true, filter:true, width:150, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Event Name', field: 'eventName', sortable: true, filter:true, width:450, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Step Name', field: 'stepName', sortable: true, filter:true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Step Type', field: 'stepType', sortable: true, filter:true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Status', field: 'status', sortable: true, filter:true, width:100, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Start Time', field: 'startTime', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'End Time', field: 'endTime', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Execution Time', field: 'executionTime', sortable: true, width:100, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, width:75, suppressMovable: true, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      "defaultTextCellRendererComponent": DefaultTextCellRendererComponent,
      "processLogsDetailsActionsComponent": MonitorLogsActionsComponent,
      "dateTimeRendererComponent": MonitorLogsDateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if (!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Start Time':
          case 'End Time':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'processLogsDetailsActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    this.selectedStepObject = JSON.parse(JSON.stringify(currentRowData));
    let stepId = this.selectedStepObject.id;
    this.monitorLogsService.getProcessLogDetailsById(this.selectedProcessId, stepId, () => {
      ["createdAt", "updatedAt", "endTime", "startTime"].forEach(key => this.monitorLogsService.formatDateString(this.monitorLogsService.processLogDetailsById, key, key + "Formatted"));
      this.selectedScreen = 'trace';
    });
  }
  
  backToMainScreen() {
    var monitorLogURL = this.isDirectMonitorLogHit ? 'monitorLogs' : 'monitor-logs';
    this.router.navigate(['landing', { outlets: { studio: monitorLogURL}}]);
  }

  getEventLog(eventObj) {
    this.monitorLogsService.getEventLog(eventObj.eventId, () => {
      ["createdAt", "updatedAt"].forEach(key => this.monitorLogsService.formatDateString(this.monitorLogsService.processEvent, key, key + "Formatted"));
      this.monitorLogsService.processEvent.schemaString = "";
      if(typeof(this.monitorLogsService.processEvent.schema) == "object") {
        this.monitorLogsService.processEvent.schemaString = JSON.stringify(this.monitorLogsService.processEvent.schema, null, 3);
      }
      this.monitorLogsService.processEvent.eventString = "";
      if(typeof(this.monitorLogsService.processEvent.event) == "object") {
        this.monitorLogsService.processEvent.eventString = JSON.stringify(this.monitorLogsService.processEvent.event, null, 3);
      }
      this.selectedScreen = "event";
    });
  }

  copySnippetCallback(params: any) {
    var iconTag = $(params.event);
    if($(params.event).is("button")) iconTag = $(params.event).find('i');
    iconTag.removeClass("fa-clone");
    iconTag.addClass("fa-check-circle");
    this.toastr.info('Copied to clipboard', '', { positionClass: 'toast-bottom-right' });
    setTimeout(() => {
      iconTag.removeClass("fa-check-circle");
      iconTag.addClass("fa-clone");
    }, 2000);
  }
  
}
