import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-custom-label",
  template: `
    {{ value }}
  `
})
export class CustomLabelComponent extends FieldType implements OnInit {
  value: string;
  ngOnInit() {
    this.value = this._get(this.model, this.field.key as string);
  }
  _get(
    obj: any,
    path: string | string[],
    defaultValue?: any
  ): any {
    if (!obj) return defaultValue as any;
    const paths = Array.isArray(path) ? path : path.split(".");
    const key = paths.splice(0, 1)[0];
    const value = obj.hasOwnProperty(key) ? obj[key] : undefined;
    return paths.length === 0
      ? value
      : value && paths[0]
        ? this._get(value, paths)
        : defaultValue;
  }
}
