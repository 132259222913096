import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { apiProperties } from '../utility/constants';
import { ApiUtilService } from '../service/api-util.service';
import { Utils } from '../utility/util';
import { AnalyticsStudioService } from './analytics-studio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSourceComponent } from './data-source/data-source.component';
import { CdkDragDrop, moveItemInArray, copyArrayItem } from '@angular/cdk/drag-drop';
import * as beautify from 'js-beautify';
import { AnalyticsPreviewComponent } from './analytics-preview/analytics-preview.component';
import { CommonService } from '../service/common.service';

declare var $: any;
declare var ResizeObserver: any;

@Component({
  selector: 'ccp-analytics-studio',
  templateUrl: './analytics-studio.component.html',
  styleUrls: ['./analytics-studio.component.css']
})
export class AnalyticsStudioComponent implements OnInit {

  @ViewChild(AnalyticsPreviewComponent, { static: false }) previewRef: AnalyticsPreviewComponent;

  
  @ViewChild('ds', { static: false }) dataSourceChildRef: DataSourceComponent;
  @ViewChild('textarea_htmlReportTemplate', { static: false }) htmlReportTemplateEditor: any;
  @ViewChild('textarea_htmlReportTmpltScope', { static: false }) htmlReportTmpltScopeEditor: any;
  @ViewChild('textarea_ReportCSSTemplplate', { static: false }) htmlReportCSSTemplplateEditor: any;
  @ViewChild('gaugePopOver', { static: false }) gaugePopOver: any;
  @ViewChild('xaxispopover', { static: false }) xaxispopover: any;
  @ViewChild('yaxispopover', { static: false }) yaxispopover: any;
  @ViewChild('seriespopover', { static: false }) seriespopover: any;
  @ViewChild('zaxispopover', { static: false }) zaxispopover: any;
  @ViewChild('bubblenamepopover', { static: false }) bubblenamepopover: any;
  @ViewChild('piecount', { static: false }) piecount: any;
  // @ViewChild(StyleSheetComponent, {static: false}) styleSheet: StyleSheetComponent;
  connectedTo: string[] = ['todo'];

  menus: any = [
    { img: 'dataSource.svg', title: 'Data Source', value: 'dataSource' },
    { img: 'filter-sort.svg', title: 'Filter & Sort', value: 'filterSort' },
    { img: 'chart-type.svg', title: 'Chart Types', value: 'chartType' },
    { img: 'chart-prop.svg', title: 'Properties', value: 'chartProp' },
    { img: 'preview.svg', title: 'Auto Preview', value: 'autoPreview' },
    { img: 'style-sheet.svg', title: 'Style Sheet', value: 'styleSheet' },
    { img: 'table-grid.svg', title: 'Data Table', value: 'dataTable'},
    { img: 'advanced.svg', title: 'Advanced', value: 'advanced' },
  ];
  showView: any = {
    xaxis: ["column", "bar", "lollipop", "radialbar", "line", "bubble", "streamgraph", "columnpyramid", "area", "heatmap", "scatter"],
    yaxis: ["column", "bar", "lollipop", "radialbar", "line", "bubble", "streamgraph", "columnpyramid", "area", "heatmap", "scatter"],
    zaxis: ["bubble", "scatter"],
    series: ["column", "bar", "lollipop", "radialbar", "line", "bubble", "streamgraph", "columnpyramid", "area", "scatter"],
    count: ["gauge", "solidgauge"],
  }
  reportId: string;
  selectedChartProp: any;
  isCreateReport: boolean = true;
  currentAPICount: number = 0;
  baseAPICount: number = 2;
  defaultAnalyticsMetadata: any = { reportType: 'Graphical', analyticsType: 'column', metadata: { type: 'API' } };
  attrData: Event;
  openedPopOver: string = null;
  analyticsConfGraphAddDet: any = {};
  conditions: any = ["=", "!=", "IN", "<", "<=", ">", ">=", "<>"];
  styleSheet: any = [{ axis: "", cond: "", value: "", startVal:"", endVal: "", color: "", backgroundColor: ""}];
  chartAxis: any = {
    column: ["xAxis", "yAxis", "series"],
    lollipop: ["xAxis", "yAxis", "series"],
    radialbar: ["xAxis", "yAxis", "series"],
    columnpyramid: ["xAxis", "yAxis", "series"],
    streamgraph: ["xAxis", "yAxis", "series"],
    bar: ["xAxis", "yAxis", "series"],
    line: ["xAxis", "yAxis", "series"],
    area: ["xAxis", "yAxis", "series"],
    pie: ["pieName", "pieValue"],
    donut: ["donutName", "donutValue"],
    bubble: ["xAxis", "yAxis", "zAxis", "series", "name"],
    scatter: ["xAxis", "yAxis", "series", "name"],
    funnel: ["yAxis", "series"],
    heatmap: ["xAxis", "yAxis", "series"],
    table: []
  };
  
  gridConfiguration: any = [];
  gridConfigNames: any = [];
  gridConfigDrpDwnOpts: any = [];
  htmlReport: any = {};
  activeHTMLEditoTab: string;
  public htmlReportTemplateOptions: any = {
    theme: 'blackboard',
    mode: 'text/html',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    extraKeys: { 'Ctrl-F': 'findPersistent', 'Cmd-F': 'findPersistent' }
  };
  public htmlReportJSONOptions: any = {
    theme: 'blackboard',
    mode: 'javascript',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    extraKeys: { 'Ctrl-F': 'findPersistent', 'Cmd-F': 'findPersistent' }
  };
  public htmlReportCSSOptions: any = {
    theme: 'blackboard',
    mode: 'text/css',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    extraKeys: { 'Ctrl-F': 'findPersistent', 'Cmd-F': 'findPersistent' }
  };
  
  constructor(public ats: AnalyticsStudioService, private apiUtilService: ApiUtilService,private cs: CommonService, 
              private route: ActivatedRoute, private toastr: ToastrService, private router: Router) {
      this.route.paramMap.subscribe(paramObject => {
        this.reportId = (paramObject['params']['id']) || '';
        if(this.reportId) this.isCreateReport = false;
      });
  }

  ngOnInit() {
    document.title = "CCS - Analytics";
    Utils.loader('#page-loader', 'show');
    this.ats.reset();
    this.getObjectMetadata(() => this.init() );
    this.getInternalFlowMetadata(() => this.init() );
    if($('#sidebar').hasClass('active') == false) {
      $('#sidebar').addClass('active');
      $('.studio-wrapper').addClass('full-width');
    }
  }

  initResizeObserver(){
    let resizeObserver = new ResizeObserver(() => {
      try {
        if(this.previewRef) this.previewRef.landingRef.chartRef.chartResize();
      } catch (error) { }
    });
    resizeObserver.observe(document.getElementById("analytics-generator"));
  }

  init() {
    if (++this.currentAPICount == this.baseAPICount) {
      if (this.isCreateReport) {
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'analytics-list'});
        this.ats.setCurrentAnalyticsMetadata(this.defaultAnalyticsMetadata);
        Utils.loader('#page-loader', 'hide');
        setTimeout(() => this.initResizeObserver() , 0);
      }
      else {
        let currentAPIProperty = apiProperties.GET_REPORT_METADATA_BY_ID;
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.reportId), currentAPIProperty.method).subscribe(
          (res: any) => {
            this.ats.showAnalyticsPreviewComp = true;
            this.ats.existingAnalytics = Utils.cloneJSON(res.body);
            this.ats.setCurrentAnalyticsMetadata(res.body);
            if(this.ats.analytics.reportType == "HTML Reports") this.ats.disableTabList.push("chartProp");
            this.cs.updateMenuBreadCrumbDetails({ label: res.body.name, parentListComponentURL: 'analytics-list'});
            if(this.ats.analyticsMetadata.type == "DATABASE") {
              setTimeout(() => this.dataSourceChildRef.getDBTablesList(), 0);
            } else Utils.loader('#page-loader', 'hide');
            setTimeout(() => this.initResizeObserver(), 0);
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            this.toastr.error("Analytics data does not exist.");
            console.error(err);
            this.router.navigate(['landing', { outlets: { studio: 'analytics-list' } }]);
          });
      }
    }
  }

  getObjectMetadata(callback: any) {
    let currentAPIProperty = apiProperties.GET_ALL_OBJECT_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ats.objectsList = res.body || [];
        this.ats.objectsMap = {};
        this.ats.objectsList.forEach((obj: any) => this.ats.objectsMap[obj.model] = obj);
        callback();
      },
      (err: any) => console.error(err)
    );
  }

  getInternalFlowMetadata(callback: any) {
    let currentAPIProperty = apiProperties.GET_ALL_INTERNAL_FLOWS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ats.internalFlowList = res.body || [];
        this.ats.internalFlowInputMap = {};
        this.ats.internalFlowList.forEach(api => {
          this.ats.internalFlowInputMap[api.input] = this.ats.internalFlowInputMap[api.input] || [];
          this.ats.internalFlowInputMap[api.input].push(api);
        });
        this.ats.internalFlowIDMap = {};
        this.ats.internalFlowList.forEach((api: any) => this.ats.internalFlowIDMap[api.idRef] = api);
        callback();
      },
      (err: any) => console.error(err)
    );
  }
  
  updateActiveTabList(tabName: string){
    const index = this.ats.activeTabList.indexOf(tabName);
    if(index != -1){
      if(tabName == "autoPreview") this.ats.isAutoPreview = false;
      if(tabName == "dataTable") this.ats.showChartDataGrid = false;
      this.ats.activeTabList.splice(index, 1);
    }else{
      if(tabName == "autoPreview") {
        this.ats.isAutoPreview = true;
        if(this.ats.showChartPreviewOverlay) this.ats.invokePreviewReload();
      }
      if(tabName == "styleSheet"){
        var analyticsConfDetails = this.ats.analytics.reportType == "Data Grid" ? this.ats.analyticsConfGrid : this.ats.analyticsConfGraphAddDet;
        this.styleSheet = Utils.cloneJSON((analyticsConfDetails.styleSheet || []).length == 0 ? this.styleSheet : analyticsConfDetails.styleSheet);
        $('#stylesheetpopup').modal('show');
      }else this.ats.activeTabList.push(tabName);
      if(tabName == "dataTable"){
        this.ats.showChartDataGrid = true;
      }
    }
  }

  getAttrData(event: any) {
    this.attrData = event;
  }

  drop(axis :any, event: CdkDragDrop<string[]>) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this.ats.analyticsConfGraphAddDet[axis] = event.previousContainer.data[event.previousIndex]['attr'];
    }
  }

  getValue(attr: any){
    if(this.ats.analyticsConfGraphAddDet[attr] && this.ats.analyticsConfGraphAddDet[attr] !== ''){
      if(this.ats.analyticsConfGraphAddDet[attr+'Condition'] && this.ats.analyticsConfGraphAddDet[attr+'Condition'] !== ''){
        return this.ats.analyticsConfGraphAddDet[attr+'Condition'].toUpperCase() + ' of '+ this.ats.analyticsConfGraphAddDet[attr];
      }else{
        return this.ats.analyticsConfGraphAddDet[attr];
      }
    }else{
      return '';
    }
  }

  closePopup(pop: any){
    pop.hide();
  }

  deleteMapping(mappingField: any, popover: string){
    switch(mappingField){
      default:
        this.ats.analyticsConfGraphAddDet[mappingField + 'Condition'] = "";
        this.ats.analyticsConfGraphAddDet[mappingField + 'Name'] = "";
        this.ats.analyticsConfGraphAddDet[mappingField] = "";
        break;
    }
    this.closePopover(popover);

  }

  showHTMLReportConfigModal() {
    this.htmlReport.template = this.ats.analyticsConfHtmlReport.template || "";
    this.htmlReport.script = this.ats.analyticsConfHtmlReport.script || "{}";
    this.htmlReport.stylesheet = this.ats.analyticsConfHtmlReport.stylesheet || "";
    $("#html-report-config-modal").modal("show");
    this.switchHTMLEditorTab("HTML");
  }

  switchHTMLEditorTab(activeHTMLEditoTab: string){
    if(this.formatHTMLReportTmpltScope(this.activeHTMLEditoTab)){
      this.activeHTMLEditoTab = activeHTMLEditoTab;
      if(this.activeHTMLEditoTab == 'HTML'){
        this.refreshReportConfigCodeMirrors("htmlReportTemplateEditor");
      }else if(this.activeHTMLEditoTab == 'SCRIPT'){
        this.refreshReportConfigCodeMirrors("htmlReportTmpltScopeEditor");
      }else if(this.activeHTMLEditoTab == 'STYLESHEET'){
        this.refreshReportConfigCodeMirrors("htmlReportCSSTemplplateEditor");
      }
    }
  }

  refreshReportConfigCodeMirrors(editor: any) {
    setTimeout(() => this[editor].codeMirror.refresh(), 200);
  }

  formatHTMLReportTmpltScope(type: string) {
    try {
      if(type == "SCRIPT") {
        new Function("return " +  this.htmlReport.script)();
        this.htmlReport.script = beautify.js(this.htmlReport.script, { indent_size: 2, space_in_empty_paren: true });
      } else if(type == "STYLESHEET"){
        this.htmlReport.stylesheet = beautify.css(this.htmlReport.stylesheet, { indent_size: 2, space_in_empty_paren: true });
      } else if(type == "HTML") {
        this.htmlReport.template = beautify.html(this.htmlReport.template, { indent_size: 2, space_in_empty_paren: true });
      }
    } catch (error) {
      if(type == "SCRIPT") this.toastr.error('Invalid Script.');
      else if(type == "STYLESHEET") this.toastr.error('Invalid Stylesheet.');
      else if(type == "HTML") this.toastr.error('Invalid HTML Template');
      return false;
    }
    return true;
  }

  saveHTMLReportConfig() {
    if(this.formatHTMLReportTmpltScope(this.activeHTMLEditoTab)){
      this.ats.analyticsConfHtmlReport.template = this.htmlReport.template;
      this.ats.analyticsConfHtmlReport.script = this.htmlReport.script;
      this.ats.analyticsConfHtmlReport.stylesheet = this.htmlReport.stylesheet;
      this.rerenderPreview(this.ats.analyticsConfHtmlReport);
      $("#html-report-config-modal").modal("hide");
    }
  }

  rerenderPreview(params: any) {
    if(this.previewRef && this.previewRef.landingRef) this.previewRef.landingRef.rerenderPreview(this.ats.analytics.reportType, params);
  }

  openPopover(popover: string) {
    this.openedPopOver = popover;
    this.analyticsConfGraphAddDet = Utils.cloneJSON(this.ats.analyticsConfGraphAddDet);
  }

  closePopover(popover: string) {
    this[popover].hide();
  }

  addProperty(popover: string) {
    Object.keys(this.analyticsConfGraphAddDet).forEach((key) => {
      this.ats.analyticsConfGraphAddDet[key] = this.analyticsConfGraphAddDet[key];
    });
    this.closePopover(popover);
  }

  addNewStyleRule() {
    this.styleSheet.push({axis: "", cond: "", value: "", startVal:"", endVal: "", color: "", backgroundColor: ""});
  }

  removeStyleRule(ind: any) {
    if(this.styleSheet.length == 1){
      this.styleSheet[0] = {axis: "", cond: "", value: "", startVal:"", endVal: "", color: "", backgroundColor: ""};
    } else {
      this.styleSheet.splice(ind, 1);
    }
  }

  saveStyleSheet() {
    var reportType = this.ats.analytics.reportType;
    var analyticsConfDetails = reportType == "Data Grid" ? this.ats.analyticsConfGrid : this.ats.analyticsConfGraphAddDet;
    var invalidStyleRule = this.styleSheet.find((style: any) => {
      if (reportType == "Data Grid") {
        if (style.cond == "<>") return !(style.startVal && style.endVal && (style.backgroundColor || style.color));
        else if (style.cond) return !(style.value && (style.backgroundColor || style.color));
        else return true;
      } else {
        if (style.cond == "<>") return !(style.startVal && style.endVal && style.color);
        else if (style.cond) return !(style.value && style.color);
        else return true;
      }
    });
    if (!invalidStyleRule) {
      analyticsConfDetails.styleSheet = Utils.cloneJSON(this.styleSheet);
      this.closeStyleSheet(analyticsConfDetails, reportType);
    } else if (this.styleSheet.length == 1) {
      if ((reportType == "Data Grid" && !invalidStyleRule.axis && !invalidStyleRule.cond && !invalidStyleRule.value && !invalidStyleRule.backgroundColor && !invalidStyleRule.color) ||
        !invalidStyleRule.axis && !invalidStyleRule.cond && !invalidStyleRule.value && !invalidStyleRule.color) {
          analyticsConfDetails.styleSheet = [];
        this.closeStyleSheet(analyticsConfDetails, reportType);
      } else this.toastr.error("Please Fill out the empty fields!");
    } else {
      this.toastr.error("Please Fill out the empty fields!");
    }
  }

  closeStyleSheet(analyticsConfDetails: any, reportType: string) {
    if(reportType == "Data Grid") this.rerenderPreview({ type: "tableConditionalStyleSheet", value: analyticsConfDetails.styleSheet });
    $('#stylesheetpopup').modal('hide');
  }

  dropColumn(event: CdkDragDrop<[]>) {
    moveItemInArray(this.gridConfiguration, event.previousIndex, event.currentIndex);
  }

  showGridConfigModal() {
    this.gridConfiguration = (this.ats.analyticsConfGrid.columnDefs || []).length == 0 ? this.buildGridConfigObject() : Utils.cloneJSON(this.ats.analyticsConfGrid.columnDefs);
    this.gridConfigNames = this.gridConfiguration.map((item: any) => { return item.name });
    this.gridConfigDrpDwnOpts = this.ats.analyticsReturnField.map((item: any) => { return item.name });
    $("#table-config-modal").modal("show");
  }

  buildGridConfigObject() {
    let returnField = this.ats.analyticsReturnField || [];
    let response = [];
    if(returnField.length > 0){
      returnField.forEach((rf: any) => {
        let displayName = rf.name.replace(/([A-Z])/g, " $1");
        displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
        response.push({name: rf.name, displayName, width: ""});
      })
    }
    return response;
  }

  removeGridConfig(idx: any, name: any) {
    let index = this.gridConfigNames.indexOf(name);
    this.gridConfigNames.splice(index, 1);
    if(this.gridConfiguration.length == 1){
      this.gridConfiguration[0] = {name: "", displayName: "", width: ""};
    } else {
      this.gridConfiguration.splice(idx, 1);
    }
  }

  saveGridConfig() {
    let nameArr = this.gridConfiguration.map((item: any) => { return item.name });
    let result = true;
    let isDuplicate = nameArr.some((item: any, idx: number) => { 
      return nameArr.indexOf(item) != idx;
    });
    if(isDuplicate){
      this.toastr.error("Duplicate Entries Found!");
      return false;
    }
    this.gridConfiguration.forEach((gc: any, idx: number) => {
      if(result){
        let isSpliced = false;
        if(gc.name && gc.name != ""){
          result = true;
        } else {
          isSpliced = true;
          this.gridConfiguration.splice(idx, 1);
        }
        if(!isSpliced){
          if(gc.displayName &&  gc.displayName != ""){
            result = true;
          } else {
            this.toastr.error("Display Name is empty for '" + gc.name + "'");
            result = false;
          }
        }
      }
    })
    if(!result){
      return false;
    }
    if(!isDuplicate && result){
      this.ats.analyticsConfGrid.columnDefs = Utils.cloneJSON(this.gridConfiguration);
      this.rerenderPreview({ type: "tableColDefs", value: this.ats.analyticsConfGrid.columnDefs });
      $("#table-config-modal").modal("hide");
    }
  }

  addGridConfigObj() {
    this.gridConfiguration.push({name: "", displayName: "", width: ""});
  }

  onGridNameChange(name: any, idx: number) {
    if(this.gridConfigNames.indexOf(name) == -1) this.gridConfigNames.push(name);
    let displayName = name.replace(/([A-Z])/g, " $1");
    this.gridConfiguration[idx].displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
    this.gridConfigNames.splice(idx, 1);
    this.gridConfigNames.push(name);
  }
}