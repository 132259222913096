import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { Utils } from './../utility/util';
import { ApiUtilService } from '../service/api-util.service';
import { EventApiService } from './../service/event-api.service';
import { DefaultTextCellRendererComponent } from './../data-grid/default-text-cell-renderer.component';
import { EventApiListActionsComponent } from './../data-grid/event-api-list-actions.component';
import { DateTimeRendererComponent } from './../data-grid/date-time-renderer.component';
import { apiProperties } from '../utility/constants';
declare var $: any;

@Component({
  selector: 'ccp-event-api-list',
  templateUrl: './event-api-list.component.html',
  styleUrls: ['./event-api-list.component.css']
})
export class EventApiListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public selectedRowObject: any= {};
  
  constructor(
    public eventApiService: EventApiService,
    private apiUtilService: ApiUtilService,
    private toastr: ToastrService,
    private router: Router,
    private cs: CommonService
  ) { }

  ngOnInit() {
    document.title = "CCS - Event API";
    this.initDataGridDetails();
    this.getAllEventApis();
    this.eventApiService.getAllEventSchemaList(null, (err: any) => console.error(err));
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Description', field: 'description', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Input Event', field: 'inputEvent', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, width: 100, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, width: 100, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      eventApiListActionsComponent: EventApiListActionsComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'eventApiListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if(type === 'edit') {
      this.eventApiService.eventSchemaList = null;
      this.eventApiService.isInternalFlow = false;
      this.router.navigate(['landing', { outlets: { studio: 'event-api-studio/' + currentRowData['inputEvent'] }}]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } else if (type === 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.name = this.selectedRowObject.name + " Clone";
      $('#clone-event-api-modal').modal('show');
    }
  }

  getAllEventApis() {
    Utils.loader('#page-loader', 'show');
    this.eventApiService.getAllEventAPIs((res: any) => {
      Utils.loader('#page-loader', 'hide');
    }, (err: any) => console.error(err));
  }

  createNew() {
    this.eventApiService.eventSchemaList = null;
    this.eventApiService.isInternalFlow = false;
    this.router.navigate(['landing', { outlets: { studio: 'event-api-studio/new' } }]);
  }

  deleteEventAPI() {
    Utils.loader('#page-loader', 'show');
    this.eventApiService.deleteEventAPI(this.selectedRowObject.id, (res: any) => {
      this.toastr.success('Event API (' + this.selectedRowObject.name + ') Deleted Successfully');
      $('#delete-confirmation-modal').modal('hide');
      Utils.loader('#page-loader', 'hide');
      this.getAllEventApis();
    }, (err: any) => {
      console.log(err);
      this.toastr.error((err.error || {}).message || 'Event API (' + this.selectedRowObject.name + ') Delete Failed');
      Utils.loader('#page-loader', 'hide');
    });
  }

  cloneEventAPI(navToEdit: boolean) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.CLONE_EVENT_API;
    var { id, name, inputEvent } = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.selectedRowObject.id), currentAPIProperty.method, { id, name, inputEvent }).subscribe(
      (res: any) => {
        $('#clone-event-api-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        if(navToEdit) this.invokeGridAction({rowData: res.body, type: 'edit'});
        else this.getAllEventApis();
        this.toastr.success("Event API Cloned Successfully.");
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

}