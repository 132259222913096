import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from "@angular/forms";
import { FormlyFormOptions } from "@ngx-formly/core";
import { EventSchemaService } from '../service/event-schema.service';
import { CommonService } from '../service/common.service';
import { ProcessListService } from './../service/process-list.service';
import { ApiUtilService } from './../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';

import * as beautify from 'js-beautify';

@Component({
  selector: 'ccp-event-schema-studio',
  templateUrl: './event-schema-studio.component.html',
  styleUrls: ['./event-schema-studio.component.css']
})
export class EventSchemaStudioComponent implements OnInit {
  @ViewChild('textarea_formlyEditor', { static: true }) formlyEditor: any;

  public isCreateFlow: boolean = false;
  public eventSchemaMetadata: any = {};
  public form = new FormGroup({});
  public userInput: any = [];
  private eventSchemaName: string;
  public model: any = {};
  public modelString: string = "";
  public formlyFieldConfig: string = '[]';
  public options: FormlyFormOptions = {};
  public isValidFormlyConfig: boolean = true;
  public formlyEditorOptions: any = {
    theme: 'blackboard',
    mode: 'application/ld+json',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  }
  public processCtxtPossibleList: any = {
    name: ["account.id", "customer.id", "product.sku", "product.addon", "product.plan", "user.type", "quantity"],
    type: ["orbitera"],
    value: ["account.id", "customer.id", "product.sku", "product.addon", "product.plan", "user.type", "quantity"]
  }
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiUtilService: ApiUtilService,
    private eventSchemaService: EventSchemaService,
    private cs: CommonService,
    public processListService: ProcessListService,
    private toastr: ToastrService
  ) { 
    this.route.paramMap.subscribe(paramObject => {
      this.eventSchemaName = (paramObject['params']['name']) || '';
    });
  }

  ngOnInit() {
    document.title = "CCS - Event Schema";
    this.eventSchemaService.getAllEventSchemaList((res: any) => {
      if(this.eventSchemaName) {
        Utils.loader('#page-loader', 'show');
        this.isCreateFlow = false;
        let currentAPIProperty = apiProperties.GET_EVENT_SCHEMA_BY_NAME;
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.eventSchemaName), currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.cs.updateMenuBreadCrumbDetails({ label: this.eventSchemaName, parentListComponentURL: 'event-schema-list'});
            this.processListService.getAllProcessList();
            this.eventSchemaMetadata = res.body || {};
            var data = JSON.stringify(this.eventSchemaMetadata.userInput);
            data = data.split("\"\/FUNCTION_START(").map((text, index) => 
              (index == 0 ? text : text.split(")FUNCTION_END\/\"").map((text, index) => 
                 (index != 0 ? text : text.replace(/\\n/g, "").replace(/\\\\/g, "\\"))
              ).join(""))
            ).join("");

            this.setFormlyFields(data);
            this.formatFormlyFieldConfig();
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            console.error(err);
            this.toastr.error("Event Schema does not exist.");
            this.router.navigate(['landing', { outlets: { studio: 'event-schema-list' } }]);
          }
        );
      }else{
        this.processListService.getAllProcessList();
        this.eventSchemaMetadata = {processContext: [], processName: "",};
        this.isCreateFlow = true;
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'event-schema-list'});
      }
    }, (err: any) => console.error(err));
  }

  setFormlyFields(value: string) {
    try {
      this.isValidFormlyConfig = true;
      this.formlyFieldConfig = value;
      this.userInput = eval(value);
      this.eventSchemaMetadata.userInput = eval(value);
    } catch (error) {
      this.isValidFormlyConfig = false;
    }
  }

  switchSchemaPreviewView(type: string){
    if(type == "scope"){
      this.modelString = JSON.stringify(this.model, null, "\t");
    }else if(type == "preview"){
      try{
        this.model = JSON.parse(this.modelString);
      }catch(e){
        this.toastr.error('Unable to parse JSON');
      }
    }
  }

  saveOrUpdateEventSchema(eventSchemaMetadata: any) {
    eventSchemaMetadata.userInput = JSON.parse(JSON.stringify(eventSchemaMetadata.userInput || [], (key, value)  => 
      (typeof (value) === "function") ? ("/FUNCTION_START(" + value.toString() + ")FUNCTION_END/") : value
    ));
    var isValidApiMetadata = () => {
      try {
        if((eventSchemaMetadata.name || '').trim() == '') throw 'Name is mandatory';
        if(this.isCreateFlow) {
          let isExist = this.eventSchemaService.eventSchemaList.find((schema: any) => schema.name === eventSchemaMetadata.name);
          if(isExist) throw 'Event schema name already exists';
        }
        return true;
      } catch (error) {
        this.toastr.error(error);
        return false;
      }
    }
    if(isValidApiMetadata()) {
      if(this.eventSchemaName) {
        this.updateEventSchema(this.eventSchemaName, eventSchemaMetadata);
      }else{
        this.saveEventSchema(eventSchemaMetadata);
      }
    }else return;
  }

  saveEventSchema(eventSchemaMetadata: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.SAVE_EVENT_SCHEMA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, eventSchemaMetadata).subscribe(
      (res: any) => {
        this.toastr.success('Event Schema Saved Successfully');
        Utils.loader('#page-loader', 'hide');
        this.router.navigate(['landing', { outlets: { studio: 'event-schema-studio/' + res.body.name }}]);
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Save Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }
 
  updateEventSchema(name: any, eventSchemaMetadata: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_EVENT_SCHEMA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", name), currentAPIProperty.method, eventSchemaMetadata).subscribe(
      (res: any) => {
        this.toastr.success('Event Schema Updated Successfully');
        Utils.loader('#page-loader', 'hide');
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['landing', { outlets: { studio: 'event-schema-studio/' + res.body.name }}]));
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Update Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  formatFormlyFieldConfig() {
    try {
      this.formlyFieldConfig = beautify.js(this.formlyFieldConfig, { indent_size: 2, space_in_empty_paren: true });
    } catch (error) {
      this.toastr.error('Unable to parse JSON');
    }
  }

  addItem() {
    let obj = {name: "", type: "", value: ""};
    if(!this.eventSchemaMetadata.processContext) {
      this.eventSchemaMetadata.processContext = [];
    }
    this.eventSchemaMetadata.processContext.push(obj);
  }

  deleteItem(idx: any) {
    this.eventSchemaMetadata.processContext.splice(idx, 1);
  }

}