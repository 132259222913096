import { Component } from "@angular/core";
import { Utils } from "./../utility/util";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'system-properties-issecured-cell-renderer',
    template: `<span class="mr-4 cursor-pointer"> <i [ngClass]="{'fa-lock lock-red' : params.data.isSecured, 'fa-unlock-alt lock-green' : !params.data.isSecured}" class="fa fa-lg" aria-hidden="true"></i> {{updatedCellValue}} </span>`,
    styles: []
})
export class SystemPropertiesIssecuredCellRendererComponent implements ICellRendererAngularComp {
    private params: any;
    public updatedCellValue: any;

    constructor() {}

    agInit(params: any): void {
        const data: any = params.value;
        this.params = params;
        this.updatedCellValue = data ? 'Secured' : 'Not Secured';
    }

    refresh(): boolean {
        return false;
    }
}