import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'system-properties-list-action-cell',
    template: `<div class="col-12 d-flex w-100 position-absolute t-0 l-0 p-0" style="line-height: 55px;">
                    <div class="col edit-icon text-center cursor-pointer" (click)="invokeAction('edit')">
                        <img class="form-control-img" src="assets/images/actions/edit.svg"/>
                        <div class="edit-icon-with-label">
                            <img class="form-control-img" src="assets/images/actions/edit-white.svg"/>
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Edit</label>
                        </div>
                    </div>
                    <div class="col delete-icon text-center cursor-pointer" (click)="invokeAction('delete')">
                        <img class="form-control-img" src="assets/images/actions/delete.svg"/>
                        <div class="delete-icon-with-label">
                            <label class="label-primary-sm pt-3 w-50 float-left cursor-pointer">Delete</label>
                            <img class="form-control-img" src="assets/images/actions/delete-white.svg"/>
                        </div>
                    </div>
               </div>`,
    styles: []
})
export class SystemPropertiesListActionComponent implements ICellRendererAngularComp {
    private params: any;
    
    agInit(params: any): void {
        this.params = params;
    }

    public invokeAction(type: string) {
        this.params.context.componentParent.invokeGridAction({rowData: this.params.node.data, type});
    }

    refresh(): boolean {
        return false;
    }
}