import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';
import { CommonService } from '../service/common.service';
import { EventApiService } from './../service/event-api.service';
import { ApiUtilService } from './../service/api-util.service';
import { InternalFlowService } from './../service/internal-flow.service';
import { DefaultTextCellRendererComponent } from './../data-grid/default-text-cell-renderer.component';
import { InternalFlowListActionsComponent } from './../data-grid/internal-flow-list-actions.component';
import { ApiMethodBadgeRendererComponent } from './../data-grid/api-method-badge-renderer.component';
import { DateTimeRendererComponent } from './../data-grid/date-time-renderer.component';
import { InternalFlowCreateModalComponent } from './../internal-flow-create-modal/internal-flow-create-modal.component';
import { ImportApiComponent } from './../import-api/import-api.component';
import { apiProperties } from '../utility/constants';
declare var $: any;

@Component({
  selector: 'ccp-internal-flow-list',
  templateUrl: './internal-flow-list.component.html',
  styleUrls: ['./internal-flow-list.component.css']
})
export class InternalFlowListComponent implements OnInit {

  @ViewChild(InternalFlowCreateModalComponent, {static: false}) internalFlowCreateModal: InternalFlowCreateModalComponent;
  @ViewChild(ImportApiComponent, {static: false}) importApi: ImportApiComponent;

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public selectedRowObject: any= {};
  private quickAccessType: string;
  
  constructor(
    public eventApiService: EventApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private cs: CommonService,
    public internalFlowService: InternalFlowService,
    private apiUtilService: ApiUtilService
  ) { }

  ngOnInit() {
    document.title = "CCS - APIs";
    this.initDataGridDetails();
    this.getAllInternalFlows();
    this.quickAccessType = this.route.snapshot.queryParamMap.get("quickAccess");
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Method', field: 'method', sortable: true, suppressMovable: true, width: 100, cellClass: 'api-method-cell' },
      { headerName: 'API Path', field: 'path', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Tag', field: 'tag', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 120, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      internalFlowListActionsComponent: InternalFlowListActionsComponent,
      apiMethodBadgeRendererComponent: ApiMethodBadgeRendererComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Updated':
          case 'Created':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Method':
            colDef.cellRenderer = 'apiMethodBadgeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'internalFlowListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if(type === 'edit') {
      this.eventApiService.eventSchemaList = null;
      this.eventApiService.isInternalFlow = true;
      this.router.navigate(['landing', { outlets: { studio: 'internal-flow-studio/' + currentRowData['id'] }}]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } else if (type === 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.name = this.selectedRowObject.name + " Clone";
      this.selectedRowObject.method = this.selectedRowObject.method;
      this.selectedRowObject.path = this.selectedRowObject.path + "/clone";
      $('#clone-internal-flow-modal').modal('show');
    }
  }

  getAllInternalFlows() {
    Utils.loader('#page-loader', 'show');
    this.internalFlowService.getAllInternalFlows((res: any) => {
      Utils.loader('#page-loader', 'hide');
      if(this.quickAccessType == "create") $("#create-api-btn-action").click();
      else if(this.quickAccessType == "import") $("#import-api-btn-action").click();
    }, (err: any) => console.error(err));
  }

  createNew() {
    this.eventApiService.eventSchemaList = null;
    this.eventApiService.isInternalFlow = true;
    this.router.navigate(['landing', { outlets: { studio: 'internal-flow-studio/new' } }]);
  }

  deleteEventAPI() {
    Utils.loader('#page-loader', 'show');
    this.internalFlowService.deleteInternalFlow(this.selectedRowObject.id, (res: any) => {
      this.toastr.success('Internal Flow (' + this.selectedRowObject.name + ') Deleted Successfully');
      $('#delete-confirmation-modal').modal('hide');
      Utils.loader('#page-loader', 'hide');
      this.getAllInternalFlows();
    }, (err: any) => {
      console.log(err);
      this.toastr.error((err.error || {}).message || 'Internal Flow (' + this.selectedRowObject.name + ') Delete Failed');
      Utils.loader('#page-loader', 'hide');
    });
  }

  cloneAPI(navToEdit: boolean) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.CLONE_INTERNAL_FLOW;
    var {id, name, method, path} = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.selectedRowObject.id), currentAPIProperty.method, {id, name, method, path}).subscribe(
      (res: any) => {
        $('#clone-internal-flow-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        if(navToEdit) this.invokeGridAction({rowData: res.body, type: 'edit'});
        else this.getAllInternalFlows();
        this.toastr.success("API Cloned Successfully.");
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Clone Failed');
        Utils.loader('#page-loader', 'hide');
      }
    );
  }

}
